import moment from "moment";

export const defaultFonts = [
  {
    title: 'Asap',
    value: 'Asap'
  },
  {
    title: 'Calibri',
    value: 'Calibri'
  },
];

export const defaultAlignment = [
  {
    title: 'Left',
    value: 'left'
  },
  {
    title: 'Center',
    value: 'center'
  },
  {
    title: 'Right',
    value: 'right'
  },
];

export const defaultCurrencies = [
  {
    label: 'EUR',
    value: '0'
  },
  {
    label: 'GBP',
    value: '2'
  },
  {
    label: 'USD',
    value: '1'
  },
];