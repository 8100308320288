import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import { modeType } from "components/pages/newTemplatePage/newTemplatePage";
import { isValidationPackManager, isPriceListManager } from "services/RoleService";
import NotFound from "components/NotFound";
import PriceListPreviewPage from 'components/pages/priceListPreviewPage/PriceListPreviewPage';

const PacksListPage = lazy(() => import('components/pages/packsListPage/packsListPage'));
const AddPackPage = lazy(() => import('components/pages/addPackPage/addPackPage'));
const NewTemplatePage = lazy(() => import('components/pages/newTemplatePage/newTemplatePage'));
const PackDetailsPage = lazy(() => import('components/pages/packDetailsPage/packDetailsPage'));
const PackPreviewPage = lazy(() => import('components/pages/packPreviewPage/packPreviewPage'));
const TemplatesListPage = lazy(() => import('components/pages/templatesListPage/templatesListPage'));

// price list
const PriceListPage = lazy(() => import('components/pages/priceListPage/priceListPage'));
const AddPriceListPage = lazy(() => import('components/pages/addPriceListPage/addPriceListPage'));
const PriceListDetailsPage = lazy(() => import('components/pages/priceListDetailsPage/priceListDetailsPage'));
const PriceListTemplatesListPage = lazy(() => import('components/pages/templatesListPage/priceListTemplates'));

const Router = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isPriceListManager() && !isValidationPackManager()) {
      navigate('/price-lists')
    }
  }, [])

  return (
    <Routes>
      {
        isValidationPackManager() ? (
          <>
            <Route
              path={'/document-details/:id'}
              element={<PackDetailsPage/>}
            />
            <Route
              path={'/new-template-editor/:documentId'}
              element={<NewTemplatePage mode={modeType.TemplateEditor} />}
            />
            <Route
              path={'/editor/:documentId'}
              element={<NewTemplatePage mode={modeType.DocumentEditor}/>}
            />
            <Route path={'/add-pack'}>
              <Route path=":team" element={<AddPackPage/>}/>
              <Route path="" element={<AddPackPage/>}/>
            </Route>
            <Route
              path={'/document-preview/:documentId'}
              element={<PackPreviewPage/>}
            />
            <Route
              path={'/templates'}
              element={<TemplatesListPage/>}
            />
            <Route
              path={'*'}
              element={<PacksListPage/>}
            />
          </>
        ) : null
      }


      {
        isPriceListManager() ? (
          <>
            <Route
              path={'/price-lists'}
              element={<PriceListPage/>}
            />
            <Route path={'/add-price'}>
              <Route path="" element={<AddPriceListPage/>}/>
            </Route>
            <Route
              path={'/price-templates'}
              element={<PriceListTemplatesListPage />}
            />
            <Route
              path={'/price-list-document-preview/:documentId'}
              element={<PriceListPreviewPage />}
            />
            <Route
              path={'/price-list-document-details/:id'}
              element={<PriceListDetailsPage/>}
            />
            <Route
              path={'/price-list-editor/:documentId'}
              element={<NewTemplatePage mode={modeType.DocumentEditor} isPriceList />}
            />
             <Route
              path={'/price-list-editor-template-editor/:documentId'}
              element={<NewTemplatePage mode={modeType.TemplateEditor} isPriceList />}
            />
            <Route
              path={'/price-list-document-details/:id'}
              element={<PackDetailsPage isPriceList />}
            />
          </>
        ) : null
      }

      <Route
        path={'*'}
        element={<NotFound/>}
      />

    </Routes>
  )
};

export default Router;
