import React, { useEffect, useState } from 'react'
import { BiMessageSquareEdit } from 'react-icons/bi';
import { MdOutlineCancel, MdOutlineDoneOutline } from 'react-icons/md';
import { BreadcrumbItem} from 'reactstrap';


type EditableBreadcrumbProps = {
  onEdited: (value: string) => void;
  initValue: string;
};

export const EditableBreadcrumb: React.FC<EditableBreadcrumbProps> = ({
  initValue,
  onEdited,
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initValue);

  useEffect(() => setValue(initValue), [initValue]);

  const handleCancelClick = () => {
    setValue(initValue);
    setEditing(false);
  };

  const handleSaveClick = () => {
    if(value.trim() === '')
        setValue(initValue);
    else
        onEdited(value.trim());
        setEditing(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  if (editing)
    return (
      <div className="d-flex">
        <input
          onKeyDown={(e) => e.stopPropagation()}
          size={40}
          className="ms-2 custom-form-input-tiny"
          placeholder="Document Name"
          value={value}
          onChange={handleInputChange}
        />
        <MdOutlineCancel
          size={18}
          className="mx-1 my-auto"
          onClick={handleCancelClick}
        />
        <MdOutlineDoneOutline
          size={18}
          className="mx-1 my-auto"
          onClick={handleSaveClick}
        />
      </div>
    );

  return (
    <BreadcrumbItem active tag="span">
      <span>{initValue}</span>
      <BiMessageSquareEdit
        size={20}
        onClick={() => setEditing(true)}
        className="align-top mx-1"
      />
    </BreadcrumbItem>
  );
};
