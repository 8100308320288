import {
  ADD_HISTORY_SNAPSHOT,
  CLEAR_DATA,
  NewTemplatePageActionTypes,
  SET_ACTIVE_SUB_TAB,
  SET_ACTIVE_SUB_TAB_ITEM,
  SET_ACTIVE_TAB,
  SET_ACTIVE_TABLE_INDEX,
  SET_DOCUMENT_TEAM,
  SET_HAS_UNSAVED_CHANGES,
  SET_INDEX,
  SET_IS_DOCUMENT_EDITOR,
  SET_IS_PAGE_LOADING,
  SET_LOADING,
  SET_NEXT_SNAP,
  SET_ORIGINAL_TABLE_DATA,
  SET_PACK_DATA,
  SET_PLANNED_PATH,
  SET_PREVIOUS_SNAP,
  SET_PRIMARY_LOGO_BLUE,
  SET_PRIMARY_LOGO_WHITE,
  SET_SECTIONS,
  SET_SHOW_UNSAVED_CHANGED_MODAL,
  SET_SUBCATEGORIES,
  SET_TEMP_STYLE,
  SET_TEMP_TABLE_DATA,
  SET_TEMP_TABLE_STYLE,
  SET_TEMPLATE_NAME,
} from './actionTypes';
import {newTemplatePageReducer, TabTypes} from 'types';
import {StyleType} from "components/pages/newTemplatePage/helpers/types";
import {HistoryHandler} from 'components/pages/newTemplatePage/helpers/historyHandler';

export interface ICategory {
  name: string,
  id: number,
  children?: ICategory[]
}
interface INewTemplatePage {
  loading: boolean,
  sectionIndex: number,
  activeTab: TabTypes,
  activeSubTab: any,
  activeSubTabItem: string | null,
  sections: any,
  primaryLogoWhite: any,
  primaryLogoBlue: any,
  isDocumentEditor: boolean,
  referenceNumber: string,
  templateName: string,
  newPackData: any,
  subcategories: ICategory | null,
  temporaryStyle: StyleType | null,
  temporaryTableStyle: StyleType | null,
  originalTableData: any
  temporaryTableData: any
  isPageLoadingActive: boolean
  pageLoadingText: string | null
  hasUnsavedChanges: boolean,
  isShowUnsavedChangedModal: boolean,
  plannedPath: string | null,
  temporaryDocumentName: string | null,
  activeTableIndex: number,
  changesHistory: any[],
  changesHistoryIndex: number,
  documentTeam: string
}

const initState: INewTemplatePage = {
  loading: false,
  sectionIndex: -1,
  activeTab: TabTypes.Sections,
  activeSubTab: null,
  activeSubTabItem: null,
  sections: [],
  primaryLogoWhite: null,
  primaryLogoBlue: null,
  isDocumentEditor: false,
  referenceNumber: '',
  templateName: '',
  newPackData: null,
  subcategories: null,
  temporaryStyle: null,
  temporaryTableStyle: null,
  originalTableData: null,
  temporaryTableData: null,
  isPageLoadingActive: false,
  pageLoadingText: null,
  hasUnsavedChanges: false,
  isShowUnsavedChangedModal: false,
  plannedPath: null,
  temporaryDocumentName: '',
  activeTableIndex: 0,
  changesHistory: [],
  changesHistoryIndex: -1,
  documentTeam: ''
}

const state = (state: newTemplatePageReducer = initState, action: NewTemplatePageActionTypes) => {
  switch (action.type) {

    case SET_ACTIVE_TABLE_INDEX:
      return Object.assign({}, state, {activeTableIndex: action.payload});

    case ADD_HISTORY_SNAPSHOT:
      const commonSnap = {
        activeTab:action.payload.activeTab ?? state.activeTab,
        activeTableIndex: state.activeTableIndex,
        referenceNumber:action.payload.refNumber ?? state.referenceNumber,
        temporaryDocumentName: action.payload.docName ?? state.temporaryDocumentName,
        templateName: action.payload.templateName ?? state.templateName,
        temporaryTableData: action.payload.tableData ?? state.temporaryTableData,
        temporaryTableStyle: action.payload.tableStyle ?? state.temporaryTableStyle,
        sections : action.payload.sections ?? state.sections,
        temporaryStyle: action.payload.styles ?? state.temporaryStyle,
      }

      let {
        updatedHistory,
        newCurrentIndex
      } = HistoryHandler.pushSnapshot(commonSnap, state.changesHistory, state.changesHistoryIndex);

      return Object.assign({}, state, {
        ...commonSnap,
        changesHistory: updatedHistory,
        changesHistoryIndex: newCurrentIndex
      });

    case SET_PREVIOUS_SNAP:
      let {
        newCurrentIndex: currentIndexAfterPrev,
        currentSnapshot: currentSnapAfterPrev
      } = HistoryHandler.previous(state.changesHistoryIndex, state.changesHistory);

      return Object.assign({}, state, {
        ...currentSnapAfterPrev,
        changesHistoryIndex: currentIndexAfterPrev
      });

    case SET_NEXT_SNAP:
      let {
        newCurrentIndex: currentIndexAfterNext,
        currentSnapshot: currentSnapAfterNext
      } = HistoryHandler.next(state.changesHistoryIndex, state.changesHistory);

      return Object.assign({}, state, {
        ...currentSnapAfterNext,
        changesHistoryIndex: currentIndexAfterNext
      });

    case SET_LOADING:
      return Object.assign({}, state, { loading: action.payload });

    case SET_IS_PAGE_LOADING:
      return Object.assign({}, state, {
        isPageLoadingActive: action.payload.loading,
        pageLoadingText: action.payload.text || null
      });

    case SET_SECTIONS:
      return Object.assign({}, state, { sections: action.payload });

    case SET_PRIMARY_LOGO_WHITE:
      return Object.assign({}, state, { primaryLogoWhite: action.payload });

    case SET_PRIMARY_LOGO_BLUE:
      return Object.assign({}, state, { primaryLogoBlue: action.payload });

    case SET_TEMPLATE_NAME:
      return Object.assign({}, state, { templateName: action.payload });

    case SET_INDEX:
      return Object.assign({}, state, { sectionIndex: action.payload });

    case CLEAR_DATA:
      return initState;

    case SET_SUBCATEGORIES:
      return Object.assign({}, state, { subcategories: action.payload });

    case SET_IS_DOCUMENT_EDITOR:
      return Object.assign({}, state, { isDocumentEditor: action.payload });

    case SET_HAS_UNSAVED_CHANGES:
      return Object.assign({}, state, { hasUnsavedChanges: action.payload });

    case SET_SHOW_UNSAVED_CHANGED_MODAL:
      return Object.assign({}, state, { isShowUnsavedChangedModal: action.payload });

    case SET_PACK_DATA:
      return Object.assign({}, state, { newPackData: action.payload });

    case SET_ACTIVE_TAB:
      return Object.assign({}, state, { activeTab: action.payload });

    case SET_ACTIVE_SUB_TAB:
      return Object.assign({}, state, { activeSubTab: action.payload });

    case SET_ACTIVE_SUB_TAB_ITEM:
      return Object.assign({}, state, { activeSubTabItem: action.payload });

    case SET_TEMP_STYLE:
      return Object.assign({}, state, { temporaryStyle: action.payload });

    case SET_TEMP_TABLE_STYLE:
      return Object.assign({}, state, { temporaryTableStyle: action.payload });

    case SET_TEMP_TABLE_DATA:
      return Object.assign({}, state, { temporaryTableData: action.payload });

    case SET_ORIGINAL_TABLE_DATA:
      return Object.assign({}, state, { originalTableData: action.payload });

    case SET_PLANNED_PATH:
      return Object.assign({}, state, { plannedPath: action.payload });

    case SET_DOCUMENT_TEAM:
      return Object.assign({}, state, { documentTeam: action.payload });

    default:
      return state;
  }
}


export default state;
