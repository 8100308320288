import { useEffect, useState } from "react";
import { DataTableType } from "../helpers/types";
import TablePreview from "./editor/tablePreview";
import ContentSkeleton from "components/pages/newTemplatePage/contentSkeleton";
import StyleCustomizer from "components/pages/newTemplatePage/tableBlock/styleCustomizer";
import ColumnsCustomizer from "components/pages/newTemplatePage/tableBlock/columnsCustomizer";
import "./tableBlock.scss";
import {
  addHistorySnapshot,
  setActiveTableIndex,
} from "redux/reducers/newTemplatePage/actionTypes";
import { useDispatch, useSelector } from "react-redux";

type TableListItemType = { title: string, index: number };

interface TableBlockProps {
  tableStyle: any; // to match changed values with "default" once
}

const TableBlock = ({tableStyle}: TableBlockProps) => {

  const {
    temporaryTableStyle: style,
    temporaryTableData: tables,
    activeTableIndex
  } = useSelector((state: any) => state.app.newTemplatePage);

  const dispatch = useDispatch();
  const [currentTableIndex, setCurrentTableIndex] = useState<number>(0);
  const [tableList, setTableList] = useState<TableListItemType[] | null>(null);
  const [currentTable, setCurrentTable] = useState<DataTableType | null>(null);

  useEffect(() => {
    if (tables) {
      setTableList(tables.map((table: DataTableType, index: number) => ({ title: table.properties.title, index })));
      setCurrentTable(tables[currentTableIndex]);
    }
  }, [tables]);

  useEffect(() => { 
    if (activeTableIndex === currentTable)
      return;
    setCurrentTableIndex(activeTableIndex);
    setCurrentTable(tables[activeTableIndex]);
  }, [activeTableIndex]);

  function updateCurrentTable(table: any) {
    if(table && table !== currentTable){
      const newTables = [...tables];
      newTables[currentTableIndex] = table;
      dispatch(addHistorySnapshot({ tableData: newTables }));
    }
  }

  const getTableTitle = (index: number) => {
    return tables[index].properties.title === ''
      ? "(empty)"
      : tables[index].properties.title
  }

  return (
    <ContentSkeleton
      sidebar={
        <>
          <div className="container-fluid">
            <ColumnsCustomizer data={currentTable} setData={updateCurrentTable}/>
          </div>
          <hr/>
          <div className="container-fluid"></div>
          {
            style
              ? <StyleCustomizer
                style={style}
                defaultStyle={tableStyle}
                setStyle={(styles) => dispatch(addHistorySnapshot({tableStyle:styles}))}
              />
              : null
          }
        </>
      }
      contentTitle={
        <>
          {tableList && currentTable && (
            <div className="section-top">
              <div className="header-content">
                {tableList.map((e, index) => (
                  <div
                    className={`section-title-item ${index === currentTableIndex && 'active'}`}
                    onClick={() => dispatch(setActiveTableIndex(index))}
                    key={index}
                  >
                    {getTableTitle(index)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      }
      content={
        currentTable && style
          ? (
            <div className="table-preview-container">
              {tables[currentTableIndex].properties.title}
              <TablePreview
                data={currentTable}
                currentTableIndex={currentTableIndex}
                style={style}
                setData={updateCurrentTable}
              />
            </div>
          )
          : (
            tables?.length ? <div>No active table</div> : null
          )
      }
    />
  )
}

export default TableBlock;
