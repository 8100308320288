import { useEffect, useState } from 'react';
import packsService from "services/packsService";
import { AxiosError, AxiosResponse } from "axios";
import { DataTableType, IStyle, StyleType } from "./types";
import {
  setPrimaryLogoBlue,
  setPrimaryLogoWhite,
  setOriginalTableData,
  addHistorySnapshot,
  setDocumentTeam
} from "redux/reducers/newTemplatePage/actionTypes";
import { useDispatch } from "react-redux";

export const useGetJson = (
  {
    id
  }: {
    id?: string
  }) => {
  const dispatch = useDispatch();
  const [tables, setTables] = useState<DataTableType[]>([]);
  const [jsonData, setJsonData] = useState<any>(null);
  const [sections, setSections] = useState<any>(null);
  const [style, setStyle] = useState<StyleType | null>(null);
  const [tableStyle, setTableStyle] = useState<StyleType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);

  const fetchData = async () => {
    if (!id) {
      return;
    }

    packsService.getPack(id, (resp: AxiosResponse) => {
      const rawData = resp.data;
      const data = JSON.parse(rawData.value);

      setJsonData(data);
      dispatch(setPrimaryLogoBlue(data.PrimaryLogoBlue));
      dispatch(setPrimaryLogoWhite(data.PrimaryLogoWhite));

      //Check if data["Table_style"] is of type IStyle
      let tableStyleForHistory: any = undefined;
      let tableDataForHistory: any = undefined;

      if (data["TableStyle"] instanceof Object) {
        const tableStyle: IStyle = data["TableStyle"];
        if (tableStyle) {
          const stylesToSet = new StyleType(tableStyle)
          setTableStyle(stylesToSet);
          tableStyleForHistory = stylesToSet;
        }
      }

      if (data["Tables"] instanceof Array<Object>) {
        const tables: DataTableType[] = data["Tables"];
        setTables(tables);
        dispatch(setOriginalTableData(tables))
        tableDataForHistory = tables;
      }

      let tempStyle;
      if (data["Style"] instanceof Object) {
        const style: IStyle = data["Style"];
        if (style) {
          tempStyle = new StyleType(style)
          setStyle(tempStyle);
        }
      }

      let sections: DataTableType[] | undefined;
      if (data["Sections"] instanceof Array<Object>) {
        sections = data["Sections"];
        setSections(sections);
      } 

      dispatch(setDocumentTeam(data.Team));
      dispatch(addHistorySnapshot({
        styles:tempStyle,
        sections: sections,
        tableStyle: tableStyleForHistory,
        tableData: tableDataForHistory,
        refNumber: data.ReferenceNumber,
        docName: data.Name
      }));

      setLoading(false);
    }, (err: AxiosError) => {
      setError(err);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return { jsonData, sections, tables, style, tableStyle, loading, error, reloadData: fetchData };
}

export const updateTables = (
  {
    updatedTables,
    updatedStyles
  }: {
    updatedTables: DataTableType[],
    updatedStyles: StyleType
  }) => {
  alert("Update Tables. New values in console");
  console.log("UPD tables: ", updatedTables);
  console.log("UPD styles: ", updatedStyles);
}
