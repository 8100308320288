import Client from 'services/axiosService';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { UploadAssetData, activityLogLoadData, packListLoadData, templatesListLoadData, updateDocumentNameData } from 'types';

const ApiUrl = process.env.REACT_APP_API_URL;

const packsProvider = {
  getPage(data: packListLoadData, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/GetAll`,
      method: 'post',
      data: data
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  getPack(id: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/json?id=${id}`,
      method: 'get',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  updatePack(data: any, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/update-json`,
      method: 'put',
      data: data
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  getPackDetails(id: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/GetById/${id}`,
      method: 'get',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  getTemplateDetails(id: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Templates/GetById/${id}`,
      method: 'get',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  getActivityLog(id: string | undefined, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/ActivityLog/GetByDocumentId/${id}`,
      method: 'get',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  getActivityLogPage(data: activityLogLoadData, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/ActivityLog/GetAll`,
      method: 'post',
      data: data
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  // getPackRegions(groupId: string, success: Function, error?: Function) {
  //   Client().request({
  //     url: `${ApiUrl}/Regions/GetAll?${groupId.length ? '&groupId=' + groupId : ''}`,
  //     method: 'get',
  //   })
  //     .then((resp: AxiosResponse) => {
  //       success(resp);
  //     }).catch((err: AxiosError) => {
  //     if (error) {
  //       error(err);
  //     }
  //   });
  // },
  // getPackGroup(regionId: string, success: Function, error?: Function) {
  //   Client().request({
  //     url: `${ApiUrl}/ProductGroups/GetAll?${regionId.length ? '&regionId=' + regionId : ''}`,
  //     method: 'get',
  //   })
  //     .then((resp: AxiosResponse) => {
  //       success(resp);
  //     }).catch((err: AxiosError) => {
  //     if (error) {
  //       error(err);
  //     }
  //   });
  // },
  getPackTemplates(teamName: string, productGroupName: string, regionName: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Templates/FilterByTeamName?${teamName.length ? 'teamName=' + teamName : ''}
            ${productGroupName.length ? '&productGroupName=' + productGroupName : ''}
            ${regionName.length ? '&regionName=' + regionName : ''}`,
      method: 'get',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  createAndPreviewDocument(model: any, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents`,
      method: 'put',
      data: model
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  archiveDocument(documentId: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/ArchiveDocument/${documentId}`,
      method: 'get',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  // previewDocument(documentId: string, success: Function, error?: Function) {
  //   Client().request({
  //     url: `${ApiUrl}/PdfGeneration/GeneratePdf`,
  //     method: 'post',
  //     data: {
  //       documentId
  //     }
  //   })
  //     .then((resp: AxiosResponse) => {
  //       success(resp);
  //     }).catch((err: AxiosError) => {
  //     if (error) {
  //       error(err);
  //     }
  //   });
  // },
  generateDocument(documentId: string, team: number, docTitle: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/PdfGeneration/GeneratePdf`,
      method: 'post',
      responseType: 'blob',
      data: {
        documentId,
        team,
        docTitle
      }
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  regenerateDocument(documentId: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/Regenerate/${documentId}`,
      method: 'patch',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  getAssetById(assetId: number, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/assetbank/asset/${assetId}`,
      method: 'get',
      responseType: 'blob',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  approveAssetById(assetId: number, documentId: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/assetbank/approve/${assetId}/${documentId}`,
      method: 'post',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  getSubcategoryOfAssetBank(categoryId: number | null, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/assetbank/subcategories${categoryId !== null ? ('?id=' + categoryId) : ''}`,
      method: 'get',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  searchAssetBankFiles(title: string, categoryId: number | null, assetType: number | null, success: Function, error?: Function) {
    const category = `${categoryId !== null ? ('&categoryId=' + categoryId) : ''}`;
    const asset = `${categoryId !== null && assetType !== null ? ('&AssetType=' + assetType) : ''}`;
    Client().request({
      url: `${ApiUrl}/Documents/assetbank/look-up?title=${title}${category}${asset}`,
      method: 'get',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  searchAssetBank(title: string, categoryId: number | null, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/assetbank/search?title=${title}&${categoryId !== null ? ('categoryId=' + categoryId) : ''}`,
      method: 'get',
      responseType: 'blob',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },

  updateDocumentStatuses(documentId: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/update-sections-statuses/${documentId}`,
      method: 'get'
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  getTemplatesPage(data: templatesListLoadData, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Templates/get-all-paged`,
      method: 'post',
      data: data,
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  updateTemplate(data: any, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Templates/edit-template`,
      method: 'put',
      data
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  deleteTemplate(templateId: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Templates/delete/${templateId}`,
      method: 'delete',
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  duplicateTemplate(templateId: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Templates/duplicate/${templateId}`,
      method: 'post',
      
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  uploadAsset(data: UploadAssetData, success: Function, error?: Function){
    const formData = new FormData();
   
    formData.append('Path', data.path);
    formData.append('Title', data.title);
    formData.append('File', data.file);
    

    axios.post(`${ApiUrl}/Documents/assetbank/uploadAsset`, formData)
    .then((resp: AxiosResponse)=>{
      success(resp);
    }).catch((err: AxiosError)=>{
      if(error){
        error(err);
      }
    });
  }
}

export default packsProvider;
