import { FunctionComponent } from 'react';
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import classes from './styles.module.scss';

type PageLoadProps = {
  loadingText?: string
}
export const GENERATING_TEXT = 'The document is being generated'

const PageLoad: FunctionComponent<PageLoadProps> = (
  {
    loadingText = 'Loading...'
  }
) => {
  return (
    <div className={classes.Backdrop}>
      <div className={classes.TextCenter}>
        <div className={classes.SpinnerBorder} role="status">
          <ClipLoader size={60} css={css`margin: auto;`}/>
          <p className={classes.LoadingText}>{loadingText}</p>
          <p className={classes.LoadingText}>Please, wait...</p>
        </div>
      </div>
    </div>
  );
}

export default PageLoad;
