import { FunctionComponent } from 'react';
import './landingPage.scss';
import { Link } from 'react-router-dom';

const LandingPage: FunctionComponent = () => (
  <div id="page-main" className="page">
    <div className="header">
      <div className="container">
        <div className="boxer-logo">
          <a href="http://www.ansell.com/" target="_blank" id="logo"><img src="/img/landing/ansell-logo.png" alt="Ansell Logo"/></a>
        </div>
      </div>
    </div>

    <div className="body">
      <section className="section section-banner">
        <img src="/img/landing/banner.jpg" alt="Global leader in safety solutions"/>
      </section>

      <section className="section section-content">
        <div className="container">
          <p>A brand templating platform that empowers anyone to easily create on-brand content. Drag-and-drop editor offers effortless,
            on-brand customization no matter the project.</p>
          <div className="boxer-icon" >
            <Link to="/" className="box-icon">
              <div className="icon">
                <img src="/img/landing/icon-validation_pack.png" alt="Validation Pack"/>
              </div>
              <h5>Validation Pack</h5>
            </Link>
            <Link to="/" className="box-icon">
              <div className="icon"><img src="/img/landing/icon-price_list.png" alt="Price List"/></div>
              <h5>Price List</h5>
            </Link>
          </div>
          <p className="noted">Ansell, <sup>&#174;</sup> and <sup>&#8482;</sup> are owned by Ansell Limited or one of its
            affiliates. &#169; 2022 Ansell Limited. All rights reserved.</p>
        </div>
      </section>
    </div>
  </div>
);

export default LandingPage;
