import classnames from "classnames";
import { setActiveSubTab, setActiveSubTabItem, setActiveTab } from "redux/reducers/newTemplatePage/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { ReactNode } from "react";
import { TabTypes } from "types";

const SectionSubListItem = (
  {
    showControls,
    item,
    name,
    title,
    type,
    withGoToTableButton = false,
    children,
  }: {
    showControls: boolean,
    item: any
    name: string,
    title: string,
    type: string,
    withGoToTableButton?: boolean,
    children?: ReactNode,
  }) => {
  const dispatch = useDispatch();
  const { activeSubTabItem } = useSelector((state: any) => state.app.newTemplatePage);

  return (
    <div className={classnames("edit-block", { 'invisible': !showControls })}>
      <div
        className={
          classnames("edit-block__item row w-100 m-0",
            { 'selected': activeSubTabItem === name })
        }
        onClick={(e: any) => {
          e.stopPropagation();
          dispatch(setActiveSubTab(item))
          dispatch(setActiveSubTabItem(name))
        }}>
        <div className="item-title col-5">
          {title}
        </div>
        <div className="item-title col-5">
          {
            withGoToTableButton ? (
              <button className="btn btn-outline-primary ms-3"
                      type="button"
                      onClick={() => dispatch(setActiveTab(TabTypes.Tables))}>
                Go to table
              </button>
            ) : type
          }
        </div>
      </div>
      {children}
    </div>
  )
}

export default SectionSubListItem;
