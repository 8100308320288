import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { teamSelection, teamSelectionStrings } from 'types';
import UserBlock from './userBlock/userBlock';
import { useSelector } from "react-redux";

const routes = [
  { path: "/document-details", text: "Document Details" },
  { path: "/notifications", text: "Notifications" },
  { path: "/add-pack", text: "Add New Validation Pack" },
  { path: "/new-template-editor", text: "New Product Template" },
  { path: "/editor", text: "Document Editor" },
  { path: "/document-preview", text: "Validation Pack Preview" },
  { path: "/templates", text: "Validation Pack Templates" },
  { path: "/price-lists", text: "Price Lists" },
  { path: "/add-price", text: "Add New Price List" },
  { path: "/document-preview", text: "Price List Preview" },
  { path: "/price-list-document-preview", text: "Price Lists" },
  { path: "/price-list-document-details", text: "Price List Details" },
  { path: "/price-list-editor-template-editor", text: "Template Editor" },
  { path: "/price-list-editor", text: "Document Editor" },
  { path: "/price-templates", text: "Price List Templates" },
  { path: "/", text: "Validation Packs" },];

const FrameTopBar: FunctionComponent = () => {
  const location = useLocation();
  const { newPackData } = useSelector((state: any) => state.app.newTemplatePage);

  const getTitleText = () => {
    let usedRoute = null;
    for (let route of routes) {
      if (location.pathname.startsWith(route.path)) {
        usedRoute = route;
        break;
      }
    }

    // Display selected team on New VP page based on URL param
    if (usedRoute && usedRoute.path === '/add-pack') {
      const teamIndex = Object.keys(teamSelection).indexOf(location.pathname.split('/')[2]);
      if (teamIndex === -1)
        return usedRoute.text;
      const team = Object.values(teamSelection)[teamIndex];
      return <div>{usedRoute.text} for the
        <span className="text-primary">{' ' + teamSelectionStrings[team]} team</span>
      </div>;
    }

    if (usedRoute && (usedRoute.path === '/new-template-editor')) {
      return newPackData?.name
    }

    return usedRoute?.text || '';
  };

  return (
    <div className="frame-header bg-light-gray p-2 position-fixed d-flex align-items-center justify-content-between">
      <h2 className="m-0 fw-bold">
        {getTitleText()}
      </h2>
      <UserBlock/>
    </div>
  );
}

export default FrameTopBar;
