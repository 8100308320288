// TODO:: refactor this
export interface DataTableType {
  properties: {
    width: number;
    title: string;
    id: string;
  };
  headers: {
    title: string;
    width: number;
    index: number;
  }[];
  rows: {
    value: string;
    index: number;
  }[][];
}

export class StyleType {
  public style: IStyle;

  constructor(style: IStyle) {
    this.style = style;
  }

  getTableStyle(): React.CSSProperties {
    return {
      // borderWidth: this.style.general.border.width,
      // borderColor: this.style.general.border.color,
      borderStyle: "solid",
      borderCollapse: "collapse",
    };
  }

  getHeaderRowStyle(): React.CSSProperties {
    //Return strongly typed style for the header row
    return {
      // backgroundColor: this.style.headers.backgroundColor,
      // color: this.style.headers.font.color,
      fontSize: this.style.headers.fontSize + "px",
      fontFamily: this.style.general.fontName,
      // fontWeight: this.style.headers.font.bold ? "bold" : "normal",
      // fontStyle: this.style.headers.font.italic ? "italic" : "normal",
      // textTransform: this.style.headers.font.transform
      //   ? this.style.headers.font.transform
      //   : "none",
      textAlign: this.style.headers.horizontalAlignment,
      // verticalAlign: this.style.headers.alignment.vertical,
    };
  }

  getHeaderCellStyle(): React.CSSProperties {
    return {
      borderWidth: '1px',
      borderColor: '#eee',
      borderStyle: "solid",
      borderCollapse: "collapse",
      padding: "5px",
    };
  }

  getRowStyle(index: number): React.CSSProperties {
    return {
      backgroundColor: index % 2 === 0
        ? ''
        : '#eee',
      // color: index % 2 === 0
      //   ? this.style.rows.font.color
      //   : this.style.altRows.font.color,
      fontFamily: this.style.general.fontName,
      // fontWeight: index % 2 === 0
      //   ? this.style.rows.font.bold ? "bold" : "normal"
      //   : this.style.altRows.font.bold ? "bold" : "normal",
      // fontStyle: index % 2 === 0
      //   ? this.style.rows.font.italic ? "italic" : "normal"
      //   : this.style.altRows.font.italic ? "italic" : "normal",
      // textTransform: index % 2 === 0
      //   ? this.style.rows.font.transform
      //   : this.style.altRows.font.transform,
      verticalAlign: 'middle',
      // verticalAlign: index % 2 === 0
      //   ? this.style.rows.alignment.vertical
      //   : this.style.altRows.alignment.vertical,
    };
  }

  getCellStyle(row: number, column: number): React.CSSProperties {
    return {
      borderWidth: '1px',
      // borderColor: this.style.general.border.color,
      borderStyle: "solid",
      borderCollapse: "collapse",
      padding: "0",
      fontSize: this.style.rows.fontSize + "px",
      textAlignLast: this.style.rows.horizontalAlignment,
      fontFamily: this.style.general.fontName,
    };
  }

  getInputStyle(row: number, column: number): React.CSSProperties {
    return {
      padding: "5px",
    };
  };
}

export type IStyle = {
  general: {
    border: {
      color: string;
      width: number;
    };
    fontName: string;
  };
  headers: RowStyle;
  rows: RowStyle;
  altRows: RowStyle;
}

interface FontStyle {
  color: string;
  size: number;
  name?: string;
  bold?: boolean;
  italic?: boolean;
  transform?: "uppercase" | "lowercase" | "capitalize";
}

interface RowStyle {
  // backgroundColor: string;
  horizontalAlignment: "left" | "center" | "right";
  fontSize: number;
}
