import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import classnames from "classnames";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ImCancelCircle, ImCheckmark } from "react-icons/im";
import { MdDragIndicator } from "react-icons/md";
import { AiFillCaretDown, AiFillCaretUp, AiOutlineEdit } from "react-icons/ai";
import { BiTrashAlt} from "react-icons/bi";
import SectionSubListItem from "components/pages/newTemplatePage/sectionBlock/sectionSubListItem";
import { useDispatch, useSelector } from "react-redux";
import { setIndex } from "redux/reducers/newTemplatePage/actionTypes";
import { useLocation } from "react-router-dom";
import { MAX_CHARS_LIMIT } from "components/pages/newTemplatePage/tableBlock/columnsCustomizer";
import { toast } from "react-toastify";
import { isMainSection } from "../helpers/sectionListHelpers";
import SectionVisibilitySwitcher from "./sectionVisibilitySwitcher";

const IconSize = 15;

export enum TITLE_SUB_ITEMS {
  titleContent = 'title_content',
  brandProduct = 'brand_product',
  brandLogo = 'brand_logo',
  ansellLogoWhite = 'ansell_logo_white',
  ansellLogoBlue = 'ansell_logo_blue',
}

enum PAGES {
  title = 'title',
  tableOfContents = 'table of contents',
  contactInformation = 'contact information',
  coverPage = 'cover page',
  backPage = 'back page'
}

// Title, Table of Contents, Contact Information should be not editable
export const isTitleEditable = (name: string) => {
  const itemName = name.toLowerCase();
  return itemName !== PAGES.title
    && itemName !== PAGES.tableOfContents
    && itemName !== PAGES.contactInformation
    && itemName !== PAGES.coverPage
    && itemName !== PAGES.backPage
}

export const canHide = (name: string) => {
  const itemName = name.toLowerCase();
  return itemName !== PAGES.title
      && itemName !== PAGES.contactInformation
      && itemName !== PAGES.coverPage
      && itemName !== PAGES.backPage
}

export interface CardProps {
  id: any;
  item: any;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number, title: string) => void;
  className?: string;
  handleTitleChange: (index: number, value:string) => void;
  handleHiddenChange: (index: number, value: boolean) => void;
  handleDeleteItem: Function;
  isPriceList: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const SectionListItem: FC<CardProps> = ({
  id,
  item,
  index,
  moveCard,
  className,
  handleHiddenChange,
  handleTitleChange,
  handleDeleteItem,
  isPriceList,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef<HTMLDivElement | null>(null);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [editTitleValue, setTitleValue] = useState<string>('');
  const [deleteModalOpen, setDeleteModal] = useState<boolean>(false);
  const isActive = useSelector((state: any) => state.app.newTemplatePage.sectionIndex) === index;
  // bug here, check later. we have isDocumentEditor in store, but it does not update for this component sometimes
  const isDocumentEditor = location.pathname.includes('editor');

  // On edit - fill edited value with existing title
  useEffect(() => {
    if (editTitle)
      setTitleValue(item.Name);
  }, [editTitle, index, item]);

  useEffect(() => {
    setShowControls(isActive);
  }, [isActive]);

  const titleEditHandler = (e: any) => {
    setTitleValue(e.target.value);
  }

  const applyNewTitle = () => {
    const newTitle = editTitleValue.trim();
    if (newTitle.length < 1)
      return toast.info('Section title cannot be empty');
    setEditTitle(false);
    handleTitleChange(index, newTitle);
  }

  const sectionItemType = 'sectionItem';
  const [{ handlerId }, drop] = useDrop<DragItem,
    void,
    { handlerId: Identifier | null }>({
    accept: sectionItemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(hoverItem: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = hoverItem.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, item.Name);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      hoverItem.index = hoverIndex;
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: sectionItemType,
    item: () => ({ id, index }),
    canDrag: () => titleIsEditable && !editTitle,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const isNotExpendableItem = index === 4 && isPriceList;

  const itemClickHandler = () => {
    if (editTitle || isNotExpendableItem) return;
    // -1 to unselect active item / or selected index
    const indexToSet = index >= 0 && isActive ? -1 : index;
    dispatch(setIndex(indexToSet));
  }

  const onDelete = () => {
    handleDeleteItem(item.Name);
    dispatch(setIndex(-1));
  };

  const handleCancelDelete = () => {
    setDeleteModal(!deleteModalOpen);
  }

  const getItemElements = () => {
    switch (item.Name.toLowerCase()) {
      case PAGES.title:
        return (
          <>
            <SectionSubListItem
              item={item}
              name={TITLE_SUB_ITEMS.titleContent}
              showControls={showControls}
              title="Content"
              type="Image"
            />
            {!isPriceList && (
              <SectionSubListItem
               item={item}
               showControls={showControls}
               name={TITLE_SUB_ITEMS.brandProduct}
               title="Brand & Product Name"
               type="Text"
             />
            )}
            <SectionSubListItem
              item={item}
              name={TITLE_SUB_ITEMS.ansellLogoWhite}
              showControls={showControls}
              title="Ansell Logo"
              type="Image"
            />
            <SectionSubListItem
              item={item}
              name={TITLE_SUB_ITEMS.brandLogo}
              showControls={showControls}
              title="Brand Logo"
              type="Image"
            />
          </>
        )
      case PAGES.tableOfContents:
        return (
          <>
            <SectionSubListItem
              item={item}
              name={TITLE_SUB_ITEMS.ansellLogoBlue}
              showControls={showControls}
              title="Ansell Logo"
              type="Image"
            />
          </>
        )
      default:
        let type = '-'
        const sectionStyle = item.Section_style.toLowerCase();

        if (sectionStyle === "pdf_content" || sectionStyle === "pdf" || sectionStyle === 'back_page') {
          type = "PDF"
        }

        if (sectionStyle === "image" || sectionStyle === "sectiongroup_title") {
          type = "Image"
        }

        if (item.Content_source[0]?.Type) {
          type = item.Content_source[0]?.Type.toUpperCase()
        }

        if (sectionStyle === "mixed_content") {
          return <>
            <SectionSubListItem
              item={item}
              showControls={showControls}
              name=''
              title="Table"
              type={type}
              withGoToTableButton={true}
            />
          </>
        }
        return <>
          <SectionSubListItem
            item={item}
            showControls={showControls}
            name=''
            title="File"
            type={type}
          />
        </>
    }
  }

  const titleIsEditable = isTitleEditable(item.Name);
  const canBeHidden = canHide(item.Name);

  drag(drop(ref));

  return (
    //@ts-ignore
    <li ref={ref}
        style={{ opacity: isDragging ? 0 : 1 }}
        className={classnames('c-pointer', className, { 'active': isActive })}
        data-handler-id={handlerId}
        onClick={itemClickHandler}
    >
      {editTitle ?
        <>
          <Input
            value={editTitleValue}
            maxLength={MAX_CHARS_LIMIT}
            className="font-14 ps-2 py-3"
            onChange={titleEditHandler}/>
          <ImCheckmark
            size={IconSize}
            className="section-item-icon item-action-icon-second"
            onClick={applyNewTitle}/>
          <ImCancelCircle
            size={IconSize}
            className="section-item-icon item-action-icon-first"
            onClick={() => setEditTitle(false)}/>
        </>
        :
        <>
          {
            titleIsEditable &&
              <MdDragIndicator
                size={IconSize}
                className={classnames("section-item-icon item-start-icon")}
                onClick={() => setEditTitle(true)}/>
          }
          <span className={classnames('section-title ps-2 py-3 font-14', {
            'draggable': titleIsEditable,
            'dragging': isDragging
          })}>
            {
              isMainSection(item)
                ? <span className="custom-multiline-badge">{item.Name}</span>
                : item.Name
            }
          </span>
          {
            !isNotExpendableItem && (
              isActive
                ? <AiFillCaretUp className="section-item-icon item-action-icon-first"/>
                : <AiFillCaretDown className="section-item-icon item-action-icon-first"/>
            )
          }
          {
            canBeHidden && showControls &&
              <SectionVisibilitySwitcher
                className={`section-item-icon ${titleIsEditable ? "item-action-icon-fourth" : "item-action-icon-second"}`}
                onSwitched={(value) => handleHiddenChange(index, value)}
                isItemHidden={item.Hidden}
                size={IconSize}/>
          }
          {
            titleIsEditable &&
              <AiOutlineEdit
                size={IconSize}
                className={classnames("section-item-icon item-action-icon-third", { 'd-none': !showControls })}
                onClick={() => setEditTitle(true)}/>
          }
          {
            titleIsEditable &&
              <BiTrashAlt
                size={IconSize}
                className={classnames("section-item-icon item-action-icon-second", { 'd-none': !showControls })}
                onClick={() => setDeleteModal(true)}/>
          }
        </>
      }

      {isDocumentEditor ? getItemElements() : null}
      <Modal isOpen={deleteModalOpen} toggle={() => setDeleteModal(!deleteModalOpen)}>
        <ModalHeader toggle={() => setDeleteModal(!deleteModalOpen)}>Delete Section</ModalHeader>
        <ModalBody>
          Are you sure you want to delete "{item.Name}" section?
        </ModalBody>
        <ModalFooter>
          <Button className="text-white bg-primary fw-bold" onClick={onDelete}>
            Delete
          </Button>
          <Button className="text-white bg-primary fw-bold" onClick={handleCancelDelete}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </li>
  )
}

export default SectionListItem
