import { useEffect } from "react";

/**
 * Enables you to use CTRL combination in your component. 
 */
const useCtrlKeyEvent = (hotkeyHandler: () => void, key: string) => {
  useEffect(() => {
    const handleHotkey = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === key) 
        hotkeyHandler();
    };

    window.addEventListener("keydown", handleHotkey);
    return () => window.removeEventListener("keydown", handleHotkey);
  }, [hotkeyHandler]);
};

export { useCtrlKeyEvent };
