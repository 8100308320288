import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import packsService from "services/packsService";
import { toast } from "react-toastify";
import PdfViewer from "components/shared/PDFViewer/PdfViewer";
import './priceListPreviewPage.scss';
import { useDispatch } from "react-redux";
import { setIsPageLoading } from "redux/reducers/newTemplatePage/actionTypes";
import priceListProvider from "providers/priceListProvider";

const PriceListPreviewPage: FunctionComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [assetId, setAssetId] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isError] = useState(!(params && params.documentId));

  useEffect(() => {
    dispatch(setIsPageLoading(true));
    priceListProvider.generatePDF(params.documentId || '', (res: any) => {
      packsService.handleGenerationErrors(res);

      setAssetId(res.headers.assetid);
      dispatch(setIsPageLoading(false));
      const blob = new Blob([res.data], { type: "application/octet-stream" });
      setPdfUrl(URL.createObjectURL(blob))
    }, (err: any) => {
      dispatch(setIsPageLoading(false));
    });
  }, []);

  const approveAssetAndRedirect = (redirectTo: string) => {
    if (!params.documentId || !assetId) return;

    packsService.approveAssetById(parseInt(assetId), params.documentId, (res: any) => {
      if (res.data.value) {
        toast.success('Saved Successfully')
        setTimeout(() => {
          navigate(redirectTo);
        }, 500);
      } else {
        // specific case for asset-bank failure
        if (res.data.success && !res.data.value) {
          return toast.error('Failed to approve an asset, please try again')
        }
        toast.error('Something went wrong')
      }
    }, (err: any) => {
      //
    });
  }

  const handleSave = () => approveAssetAndRedirect('/price-lists');
  const handleSaveAndEdit = () => approveAssetAndRedirect('/price-list-editor/' + params.documentId);
  const handleBack = () => navigate(-1);

  return (
    <div className="h-100 overflow-auto">
      {!isError &&
        <div>
          <div className="row mx-5 mt-2 mb-2">
            <div className="col-8"/>
            <div className="button-wrapper d-flex justify-content-end p-1">
              <Button color="danger"
                      className="ms-2 font-14 font-weight-500"
                      onClick={handleBack}
                      outline
              >
                Back
              </Button>
              <Button color="primary"
                      className="ms-2 font-14 font-weight-500"
                      onClick={handleSave}>
                Save
              </Button>
              <Button color="primary"
                      className="ms-2 font-14 font-weight-500"
                      onClick={handleSaveAndEdit}
                      outline
              >
                Save and Edit
              </Button>
            </div>
          </div>

          {
            pdfUrl &&
            <div className="DocumentContainer">
              <PdfViewer pdfUrl={pdfUrl}/>
            </div>
          }
        </div>}

      {isError && <div className="text-center">Something went wrong</div>}
    </div>
  );
};

export default PriceListPreviewPage;
