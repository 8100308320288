import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import store from 'redux/store';
import { clearAuthData } from '../redux/reducers/auth/actionTypes';

const instance = axios;

function createClient(stringifyRequest: boolean = true) {
  const token = store.getState().app.auth.bearerToken;
  let config: AxiosRequestConfig = {
    baseURL: process.env.API_URL,
    timeout: 600000,
    headers: {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    responseType: 'json',
    transformRequest: stringifyRequest ? [
      function (data: AxiosResponse) {
        return JSON.stringify(data);
      }
    ] : []
  };

  return instance.create(config);
}

export default function Client(stringifyRequest = true) {
  const client = createClient(stringifyRequest);

  client.interceptors.response.use(function (response) {
    // validation error (status code 400)
    if (response.status === 200 && response.data.code === 400) {
      const message = response.data.code === 400
        ? JSON.parse(response.data.message)[0]
        : 'Something went wrong';

      return toast.warning(message);
    }

    return response;
  }, function (error) {
    if(error.response.status === 401){
      store.dispatch(clearAuthData());
    }
    // as backend always returns 200 status, just doing nothing
    return Promise.reject(error);
  });
  return client;
};

