import { useEffect } from 'react';
import { DataTableType } from "components/pages/newTemplatePage/helpers/types";
import SidebarSection from "components/pages/newTemplatePage/tableBlock/sidebarSection";
import { Field, FieldArray, Form, Formik } from "formik";
import { HiChevronDown, HiChevronUp, HiMinusCircle, HiPlusCircle } from "react-icons/hi";
import StylingBlock from "components/pages/newTemplatePage/tableBlock/stylingBlock";

export const MAX_COLUMNS_NUMBER = 10;
export const MAX_COLUMN_ROWS_NUMBER = 100;
export const MIN_COLUMN_PX_WIDTH = 50;
export const MAX_CHARS_LIMIT = 100;

const ColumnsCustomizer = (
  {
    data,
    setData,
  }: {
    data: DataTableType | null;
    setData: Function;
  }
) => {
  const deleteColumn = (index: number) => {
    //For each element in data.rows remove element where e.index === index
    let newData = data;
    if (data) {
      data.rows.forEach((row, i) => {
        row.forEach((e, j) => {
            if (e.index === index && newData) {
              newData.rows[i].splice(j, 1);
            }
          }
        );
      });
      setData(newData);
    }
  }

  const addColumn = (index: number) => {
    let newData = data;
    newData?.rows?.forEach((row) => {
        row.push({
          index: index,
          value: "",
        });
      }
    );
    setData(newData);
  }


  useEffect(() => {}, [setData])

  var initVs = {
    headers: data?.headers ?? [],
    maxIndex: data?.headers.length ?? 0,
    properties: data?.properties ?? {}
  }

  return (
    <SidebarSection title="">
      {(data && data.rows) ? (
        <Formik
          enableReinitialize={true}
          initialValues={initVs}
          onSubmit={(values, { setSubmitting }) => {
            setData({ ...data, headers: values.headers, properties: values.properties });
            setSubmitting(false);
          }}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={false}
          validate={(values) => {
            // To set values only in case of onBlur if text of a cell was changed 
            // and in case of onChange if a row was added/deleted/moved, I perform
            // validation on both of events and specify whether validation is needed.
            if(JSON.stringify(initVs) !== JSON.stringify(values))
              if((values as any).validationRequested != false)
                setData({ ...data, headers: values.headers, properties: values.properties });
          }}
        >
          {({ values, handleBlur, handleChange }) => (
            <Form>
              <StylingBlock title="Table Title">
                <Field
                  onKeyDown={(e:Event) => e.stopPropagation()}
                  name={`properties.title`}
                  className="w-100"
                  type="text"
                  maxLength={MAX_CHARS_LIMIT}
                  onChange={(e: Event) => {
                    Object.assign(values, {validationRequested: false});
                    handleChange(e);
                  }}
                  onBlur={(e:any) => {
                    Object.assign(values, {validationRequested: true});
                    if(data.properties.title !== e.target.value)
                      handleBlur(e);
                  }}
                />
              </StylingBlock>
              <StylingBlock title="Headers">
                <FieldArray name="headers" validateOnChange={true}>
                  {({ insert, remove, push, move }) => (
                    <div className="pt-4">
                      <div>
                        <div>
                          {values.headers.length > 0 &&
                            values.headers.map((header, index) => (

                              <div className="row mb-1" key={index}>
                                <div className=" col col-9">
                                  <Field
                                    name={`headers[${index}.title]`}
                                    className="w-100"
                                    type="text"
                                    maxLength={MAX_CHARS_LIMIT}
                                    onKeyDown={(e:Event) => e.stopPropagation()}
                                    onChange={(e: Event) => {
                                      Object.assign(values, {validationRequested: false});
                                      handleChange(e);
                                    }}
                                    onBlur={(e:any) => {
                                      Object.assign(values, {validationRequested: true});
                                      if(data.headers[index].title !== e.target.value)
                                        handleBlur(e);
                                    }}
                                  />
                                </div>

                                <div className="col col-3 p-0">
                                  <div className="d-flex">
                                    <button
                                      className="icon-btn p-0"
                                      type="button"
                                      onClick={(e) => move(index, index - 1)}
                                    >
                                      <HiChevronUp className="text-gray-300 hover:text-gray-400 h-5"/>
                                    </button>
                                    <button
                                      className="icon-btn p-0"
                                      type="button"
                                      onClick={(e) => move(index, index + 1)}
                                    >
                                      <HiChevronDown className="text-gray-300 hover:text-gray-400 h-5"/>
                                    </button>
                                    <button
                                      className="icon-btn p-0"
                                      type="button"
                                      onClick={(e) => {
                                        remove(index);
                                        deleteColumn(index);
                                      }}
                                    >
                                      <HiMinusCircle className="text-gray-300 hover:text-gray-400 h-5"/>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                      {
                        data?.headers.length < MAX_COLUMNS_NUMBER ? (
                          <button
                            type="button"
                            className="icon-btn w-100 text-center mt-2"
                            onClick={() => {
                              push({ title: "", width: 40, index: values.maxIndex });
                              addColumn(values.maxIndex);
                              values.maxIndex++;
                            }}
                          >
                            <HiPlusCircle className=""/>
                          </button>
                        ) : null
                      }

                    </div>
                  )}
                </FieldArray>
              </StylingBlock>
            </Form>
          )}
        </Formik>
      ) : (
        <p>No tables in current VP</p>
      )}
    </SidebarSection>
  );
}


export default ColumnsCustomizer;
