import React, {FunctionComponent, useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router";
import { Breadcrumb, BreadcrumbItem, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import {
    addHistorySnapshot,
} from "redux/reducers/newTemplatePage/actionTypes";
import { MAX_CHARS_LIMIT } from "components/pages/newTemplatePage/tableBlock/columnsCustomizer";
import { EditableBreadcrumb } from "components/shared/customTextFields/editableBreadcrumb";
import DelayedTextField from "../../../shared/customTextFields/delayedTextField";

const NewTemplateHead: FunctionComponent<{
  handleSave: () => void,
  handleGoBack: () => void,
  loading: boolean,
  isPriceList: boolean
}> = (
  {
    handleSave,
    handleGoBack,
    loading,
    isPriceList
  }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    newPackData,
    referenceNumber,
    templateName,
    isDocumentEditor,
    temporaryDocumentName
  } = useSelector((state: any) => state.app.newTemplatePage);

  const params = useParams();

  const changeRefNumber = (value:string) => dispatch(addHistorySnapshot({refNumber: value}));
  const changeTemplateName = (value: string) => dispatch(addHistorySnapshot({ templateName: value}));
  const changeDocumentName = (value:string) => dispatch(addHistorySnapshot({docName: value}));

  return (
    <div className="head-container container-fluid p-0">
      <div className="d-flex justify-content-between">
      <Breadcrumb listTag="nav" className="font-14 mb-4 align-items-center">
          <BreadcrumbItem
            onClick={() => navigate(isDocumentEditor ? '/' : '/templates')}
            className="c-pointer"
            tag="span"
          >
            {
              isDocumentEditor ? (isPriceList ? 'Price Lists' : 'Validation Packs') : 'Templates'
            }
          </BreadcrumbItem>

          { isDocumentEditor
              ? <EditableBreadcrumb onEdited={changeDocumentName} initValue={temporaryDocumentName}/>
              : <BreadcrumbItem active tag="span">
                  {newPackData?.name}
                </BreadcrumbItem>
          }


        </Breadcrumb>
        <div>
          <div className="button-wrapper d-flex ms-3 p-1">
            <button className="btn btn-outline-primary ms-3" type="button" onClick={handleGoBack}>
              Cancel
            </button>
            <button className="btn btn-primary ms-3" type="button" onClick={handleSave} disabled={loading}>
              {
                isDocumentEditor ? 'Save and Regenerate' : 'Save'
              }
            </button>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className="d-flex mb-4 font-14 ">

          {
            isDocumentEditor
              ? (
                <div className="head-field-wrapper me-5">
                  <Label for="template-name"> Template Name: </Label>
                  <Input
                    className="custom-form-input font-14 ms-1"
                    id="template-name"
                    name="template-name"
                    value={newPackData?.templateName}
                    disabled
                  />
                </div>
              ) : (
                <div className="head-field-wrapper me-5">
                  <Label for="template-name"> Name: </Label>
                  <DelayedTextField
                    initialValue={templateName}
                    onChanged={changeTemplateName}
                    className="custom-form-input font-14 ms-1"
                    id="template-name"
                    name="template-name"
                    maxLength={MAX_CHARS_LIMIT}
                  />
                </div>
              )
          }

          {
            isDocumentEditor && !isPriceList
              ? (
                <div className="head-field-wrapper me-5">
                  <Label for="template-name"> Reference Number: </Label>
                  <DelayedTextField
                    initialValue={referenceNumber}
                    onChanged={changeRefNumber}
                    className="custom-form-input font-14"
                    id="ref-number"
                    name="ref-number"
                    maxLength={MAX_CHARS_LIMIT}
                  />
                </div>
              ) : null
          }
        </div>
      </div>
    </div>
  )
}

export default NewTemplateHead;
