import React, { forwardRef, FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';

type ScrollbarProps = {
  children?: ReactNode,
  className?: string,
  onScrollFunc?: Function,
  ref?: any
}

const ScrollbarComponent: FunctionComponent<ScrollbarProps> = forwardRef<HTMLDivElement, ScrollbarProps>((props, ref) => {
  const { children, className = '', onScrollFunc } = props;

  const scrollCapture = (e: any) => {
    const target = e.currentTarget;
    if ((target.scrollTop + target.clientHeight) + 10 >= target.scrollHeight) {
      if (onScrollFunc)
        onScrollFunc();
    }
  }

  return (
    <div
      className={classnames('scrollbar w-100, h-100', className)}
      style={{ overflow: 'auto' }}
      ref={ref}
      onScroll={scrollCapture}
    >
      {children}
    </div>
  );
});

export default ScrollbarComponent;
