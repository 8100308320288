import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import _ from "lodash";

export const useTableDataDiffers = () => {
  const [isChanged, setIsChanged] = useState(false);
  const {
    temporaryTableData,
    originalTableData
  } = useSelector((state: any) => state.app.newTemplatePage);

  useEffect(() => {
    if (!(temporaryTableData && originalTableData)) return;

    temporaryTableData.forEach((table: any, i: number) => {
      if (!_.isEqual(table, originalTableData[i])) {
        setIsChanged(true)
      }
    })
  }, [originalTableData, temporaryTableData]);

  return { isChanged };
}
