import { FunctionComponent } from 'react';
import { css } from "@emotion/react";
import classNames from 'classnames';
import { ClipLoader, BounceLoader } from "react-spinners";

type SpinnerLoadProps = {
  className?: string,
  size?: number,
  spin?: boolean
}

const SpinnerLoad: FunctionComponent<SpinnerLoadProps> = (
  {
    size = 60,
    spin = false,
    className
  }) => {
  return (
    <div className={classNames(className)}>
      {
        spin
          ? <ClipLoader size={size} css={css`margin: auto;`}/>
          : <BounceLoader size={size} css={css`margin: auto;`}/>
      }
    </div>
  );
}

export default SpinnerLoad;
