import classNames from "classnames"
import { FunctionComponent } from "react"
import classes from './styles.module.scss';

type CustomCheckboxProps = {
    id : string,
    label: string
    value: boolean,
    onChange: () =>void
}

const CustomCheckbox : FunctionComponent<CustomCheckboxProps> = ({id, label, value, onChange}) => {
    return (
        <div className={classes.Checkbox}>
            <input
            type="checkbox"
             className={classes.CheckboxInput}
             id={id}
             checked={value}
             onChange={onChange}/>
             <label htmlFor={id} className={classes.CheckboxLabel}>{label}</label>
        </div>
    );
}

export default CustomCheckbox;