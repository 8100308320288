import React, { FunctionComponent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { setPlannedPath, setShowUnsavedChangedModal } from "redux/reducers/newTemplatePage/actionTypes";
import { useTableDataDiffers } from "components/pages/newTemplatePage/helpers/isTableDataDiffers";

type FrameSideNavItemProps = {
  className?: string;
  path: string;
  withChildren?: string[];
  icon: React.ReactNode;
  notificationsCount?: number;
  toolTipText: string
};

const FrameSideNavItem: FunctionComponent<FrameSideNavItemProps> = (
  {
    className,
    path,
    withChildren,
    icon,
    notificationsCount,
    toolTipText
  }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [showNotification] = useState(false);
  const isTableDataChanged = useTableDataDiffers();
  const toolTipId = toolTipText.split(' ').join('');

  const { hasUnsavedChanges } = useSelector((state: any) => state.app.newTemplatePage);

  const handleClick = () => {
    if (location.pathname.includes('/editor') || location.pathname.includes('/new-template-editor')) {
      if (hasUnsavedChanges || isTableDataChanged.isChanged) {
        dispatch(setPlannedPath(path));
        return dispatch(setShowUnsavedChangedModal(true))
      }
    } 

    navigate(path);
  };

  const shouldBeActive = () => {
    return (
      withChildren?.some((path) => location.pathname.startsWith(path)) ||
      location.pathname === path
    );
  };

  return (
    <>
      <div
        id={toolTipId}
        className={classnames(className, { 'frame-icon-solid': shouldBeActive() })}
        onClick={handleClick}
      >
        {icon}
        {notificationsCount && showNotification && (
          <span className="notifications-count position-absolute d-flex justify-content-center align-items-center">
            {notificationsCount}
          </span>
        )}
      </div>
      <UncontrolledTooltip
        target={toolTipId}
        placement="right"
      >
        {toolTipText}
      </UncontrolledTooltip>
    </>
  );
}

export default FrameSideNavItem;
