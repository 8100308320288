import React from 'react';

const StylingBlock = (
  {
    title,
    children,
  }: {
    title: string;
    children: React.ReactNode;
  }
) => {
  return (
    <div className="mb-5">
      <h3 className=" mb-2 font-semibold">{title}</h3>
      <div className="pl-3">{children}</div>
    </div>
  );
}

export default StylingBlock;
