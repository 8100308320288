import { useEffect, useRef, useState } from "react";
import { Field, FieldArray, Form, Formik, withFormik } from "formik";
import { DataTableType, StyleType } from "../../helpers/types";
import { HiChevronDown, HiChevronUp, HiMinusCircle, HiPlusCircle, } from "react-icons/hi";
import useDebounce from "components/shared/hooks/useDebounce";
import "./tablePreview.scss";
import { setTempTableData } from "redux/reducers/newTemplatePage/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  MAX_COLUMN_ROWS_NUMBER,
  MIN_COLUMN_PX_WIDTH,
  MAX_CHARS_LIMIT
} from "components/pages/newTemplatePage/tableBlock/columnsCustomizer";

export default function TablePreview(
  {
    data,
    currentTableIndex,
    setData,
    style,
  }: {
    data: DataTableType;
    currentTableIndex: number,
    setData: Function,
    style: StyleType;
  }) {
  const dispatch = useDispatch();

  const [width, setWidth] = useState('0');
  const debounceWidth = useDebounce(width, 500);
  const tableParentRef = useRef(null);
  const tableRef = useRef(null);

  const order = data.headers.map((header) => header.index);

  const { temporaryTableData } = useSelector((state: any) => state.app.newTemplatePage);
  const [headersCount, setHeadersCount] = useState(temporaryTableData[currentTableIndex].headers.length);
  const [isInitiated, setIsInitiated] = useState(false);

  let resizeElement: any,
    startSize: any,
    startX: any;


  const handleTableColumnsResize = () => {
    /**
     * Tricky stuff to manipulate table width generation
     * 510 "points" is max-width of the table, in generated document
     *
     * What I`m doing here is
     * 1) finding table width with some number of columns (as 100%)
     * 2) after resizing - check what column was changed and size (const index... && const itemWidth...)
     * 3) calculate percentage of table (for UI)
     * 4) divide columns from this percentages to have 3 columns as 510 in total (const value...)
     */
    if (tableRef.current && tableParentRef.current) {
      const tableHeader: HTMLDivElement = tableRef.current
      const updatedTableData = [...temporaryTableData]
      let tableWidth = 0

      each(tableHeader.childNodes, (childNode: any) => {
        tableWidth += parseInt(childNode.style.width.replace('px', ''));
      })

      each(tableHeader.childNodes, (childNode: any) => {
        const index = childNode.getAttribute("data-index");
        const itemWidth = parseInt(childNode.style.width.replace('px', ''));

        // X is what percent of y?
        const percentageOfTable = (itemWidth * 100) / tableWidth;

        // What is p% of x?
        const value = (percentageOfTable * 510) / 100
        updatedTableData[currentTableIndex].headers[index].width = Math.floor(value);
      })

      dispatch(setTempTableData(updatedTableData));
    }
  }

  useEffect(() => {
    handleTableColumnsResize()
  }, [debounceWidth]);

  useEffect(() => {
    const headerLength = temporaryTableData[currentTableIndex].headers.length;
    if (headerLength && headerLength !== headersCount) {
      setHeadersCount(headerLength)
    }
  }, [temporaryTableData])

  useEffect(() => {
    if (isInitiated) {
      // update width when added new columns
      handleTableColumnsResize()
    } else {
      setIsInitiated(true)
    }
  }, [headersCount]);

  useEffect(() => {
    each(document.querySelectorAll('th'), (elem: any) => {
      let trigger = document.createElement('span');
      trigger.className = 'resizeTrigger';
      trigger.addEventListener('mousedown', beginResize);
      elem.appendChild(trigger);
    })

    document.addEventListener('mousemove', resizing);
    document.addEventListener('mouseup', killResize);

    return function cleanup() {
      window.removeEventListener('mousedown', beginResize);
      window.removeEventListener('mousemove', resizing);
      window.removeEventListener('mouseup', killResize);
    }
  }, [data])

  const each = (arr: any, fn: any) => {
    let i = arr.length;
    while (--i > -1) {
      fn(arr[i]);
    }
  }

  const px = (val: any) => [val, 'px'].join('');

  const resizing = (e: any) => {
    if (resizeElement) {
      let diff = e.pageX - startX;
      resizeElement.style.width = px(startSize + diff);
      setWidth((startSize + diff).toString());
    }
  }

  const beginResize = (e: any) => {
    // killResize()
    let th = e.target.parentElement;
    resizeElement = th;
    startSize = th.clientWidth;
    startX = e.pageX;
  }

  const killResize = () => {
    resizeElement = null;
    startSize = null;
    startX = null;
  }

  return (
    <div ref={tableParentRef}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          table: data,
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validate={(values) => {
          // To set values only in case of onBlur if text of a cell was changed 
          // and in case of onChange if a row was added/deleted/moved, I perform
          // validation on both of events and specify whether validation is needed.
          const validationRequested = (values as any).validationRequested;
          if(validationRequested !== false)
            setData(values.table);
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setSubmitting(false);
        }}
      >
        {({
            values,
            handleBlur,
            handleChange
          }) => (
          <Form>
            <FieldArray
              name="table.rows"
              render={(arrayHelpers) => (
                <>
                  <table
                    style={{
                      ...style.getTableStyle(),
                      width: debounceWidth,
                      // borderRight: "none",
                    }}
                    className="table-preview"
                  >
                    <thead>
                    <tr style={style.getHeaderRowStyle()} ref={tableRef}>
                      {data.headers.map((header: any, i: number) => {
                        return (
                          <th
                            key={i}
                            data-index={i}
                            style={{
                              ...style.getHeaderCellStyle(),
                              width: header.width,
                              minWidth: `${MIN_COLUMN_PX_WIDTH}px`
                            }}
                          >
                            {header.title}
                          </th>
                        );
                      })}
                    </tr>
                    </thead>
                    <tbody>
                    {values.table.rows.map((row: any[], i: number) => (
                      <tr
                        key={i}
                        style={style.getRowStyle(i)}
                        className="table__row"
                      >
                        {order.map((element: number, index: number) => {
                          const orderedIndex = row.findIndex(
                            (e) => e.index === element
                          );

                          return (
                            <td
                              key={index}
                              style={style.getCellStyle(i, orderedIndex)}
                            >
                              <Field
                                as="textarea"
                                name={`table.rows[${i}][${orderedIndex}].value`}
                                style={style.getInputStyle(i, orderedIndex)}
                                type="text"
                                placeholder={`...`}
                                onKeyDown={(e:Event) => e.stopPropagation()}
                                onChange={(e: Event) => {
                                  Object.assign(values, {validationRequested: false});
                                  handleChange(e);
                                }}
                                onBlur={(e:any) => {
                                  Object.assign(values, {validationRequested: true});
                                  if(data.rows[i][orderedIndex].value !== e.target.value)
                                    handleBlur(e);
                                }}
                                className="table__input"
                                maxLength={MAX_CHARS_LIMIT}
                              />
                            </td>
                          );
                        })}
                        <td className="table__helper-col">
                          <div className="table__helper">
                            <button
                              type="button"
                              className="table__helper-btn"
                              onClick={() => arrayHelpers.move(i, i - 1)}
                            >
                              <HiChevronUp/>
                            </button>
                            <button
                              type="button"
                              className="table__helper-btn"
                              onClick={() => arrayHelpers.move(i, i + 1)}
                            >
                              <HiChevronDown/>
                            </button>
                            <button
                              type="button"
                              className="table__helper-btn"
                              onClick={() => arrayHelpers.remove(i)}
                            >
                              <HiMinusCircle/>
                            </button>
                            {
                              data.rows.length < MAX_COLUMN_ROWS_NUMBER ? (
                                <button
                                  type="button"
                                  className="table__helper-btn"
                                  onClick={(e) => {
                                    const newRow = data.headers.map((header) => {
                                      return {
                                        index: header.index,
                                        value: "",
                                      };
                                    });
                                    arrayHelpers.insert(i, newRow);
                                  }}
                                >
                                  <HiPlusCircle/>
                                </button>
                              ) : null
                            }

                          </div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                  {
                    data.rows.length < MAX_COLUMN_ROWS_NUMBER ? (
                      <div className="w-full text-center mt-1">
                        <button
                          type="button"
                          className="table__add-row"
                          onClick={() => {
                            const newRow = data.headers.map((header) => {
                              return {
                                index: header.index,
                                value: "",
                              };
                            });
                            arrayHelpers.push(newRow);
                          }}
                        >
                          <HiPlusCircle/>
                        </button>
                      </div>
                    ) : null
                  }
                </>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
