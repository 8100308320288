import { Field, useFormikContext } from "formik";
import { HiRefresh } from "react-icons/hi";

interface SelectOption {
  value: string | number;
  title: string;
}

const BlockField = (
  {
    title,
    inputName,
    inputType,
    defaultValue,
    selectOptions,
    isAssetStyle = false,
  }: {
    title: string;
    inputName: string;
    inputType: string;
    defaultValue?: string | number;
    selectOptions?: SelectOption[],
    isAssetStyle?: boolean
  }
) => {
  const { setFieldValue, getFieldProps } = useFormikContext();

  let input

  if (inputType === 'color') {
    input = (
      <Field
        name={inputName}
        className="w-100 d-block"
        type={inputType}
        value={getFieldProps(inputName).value}
      />
    );
  } else if (inputType === 'select') {
    if (!selectOptions) selectOptions = [];
    input = (
      <Field
        as="select"
        name={inputName}
        className="w-100 d-block"
        defaultValue={defaultValue}
      >
        {selectOptions.map((item) => (
          <option
            key={item.value}
            value={item.value}
          >
            {item.title}
          </option>
        ))}
      </Field>
    );
  } else {
    input = <Field
      name={inputName}
      className="w-100 border-b border-b-gray-300 px-1 focus:outline-none focus:border-b-gray-900 mb-1"
      type={inputType}
      value={getFieldProps(inputName).value}
    />
  }

  let inputBlock = (
    <>
      <span className="col col-6">{title}</span>
      <div className="col p-0">
        {input}
      </div>
    </>
  )

  if (isAssetStyle) {
    inputBlock = (
      <>
        <span className="col col-12 pb-1">{title}:</span>
        <div className="col col-12 pb-1">
          {input}
        </div>
      </>
    )
  }

  return (
    <div className="row mb-1">
      {inputBlock}
      <div style={{ width: "8px" }}>
        {defaultValue && (defaultValue !== getFieldProps(inputName).value) && (
          <button
            onClick={(e) => {
              setFieldValue(inputName, defaultValue);
            }}
            type="button"
            className="icon-btn"
          >
            <HiRefresh className="text-gray-300 hover:text-gray-400 h-5 w-5"/>
          </button>
        )}
      </div>
    </div>
  );
}

export default BlockField;
