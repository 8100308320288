import { cloneDeep } from "lodash";

type PushHistoryResult = {
  updatedHistory: any[];
  newCurrentIndex: number;
};

type MoveHistoryResult = {
  newCurrentIndex: number;
  currentSnapshot: any;
};

/**
 * Utility class that provides methods for handling history actions.
 */
export class HistoryHandler {
  
  private static readonly MAX_HISTORY_SIZE: number = 30;

  /**
   * Retrieves the previous history snapshot based on the current index. Uses deep copying.
   * @param currentIndex - The current index in the history.
   * @param history - The current array of history snapshots.
   * @returns An object containing the new current index and the current snapshot.
   */
  public static previous(currentIndex: number, history: any[]): MoveHistoryResult {
    const newCurrentIndex = currentIndex - 1;
    if (newCurrentIndex >= 0 && newCurrentIndex < history.length) {
      return {
        newCurrentIndex,
        currentSnapshot: cloneDeep(history[newCurrentIndex]),
      };
    }
    return {
      newCurrentIndex: currentIndex,
      currentSnapshot: history[currentIndex],
    };
  }

  /**
   * Retrieves the next history snapshot based on the current index. Uses deep copying.
   * @param currentIndex - The current index in the history.
   * @param history - The current array of history snapshots.
   * @returns An object containing the new current index and the current snapshot.
   */
  public static next(currentIndex: number, history: any[]): MoveHistoryResult {
    const newCurrentIndex = currentIndex + 1;
    if (newCurrentIndex >= 0 && newCurrentIndex < history.length) {
      return {
        newCurrentIndex,
        currentSnapshot: cloneDeep(history[newCurrentIndex]),
      };
    }
    return {
      newCurrentIndex: currentIndex,
      currentSnapshot: history[currentIndex],
    };
  }

   /**
   * Pushes a new history snapshot to the history array. Uses deep copying.
   * @param snapshot - The new snapshot to be added.
   * @param history - The current array of history snapshots.
   * @param currentIndex - The current index in the history.
   * @returns An object containing the updated history array and the new current index.
   */
  public static pushSnapshot(snapshot: any, history: any[], currentIndex: number): PushHistoryResult {
    /* Control history size by removing the oldest item */
    const updatedHistory = history.slice(0, currentIndex + 1);
    if (updatedHistory.length === HistoryHandler.MAX_HISTORY_SIZE)
      updatedHistory.shift();
    /* Add new snapshot */
    const deepSnapshotCopy = cloneDeep(snapshot);
    updatedHistory.push(deepSnapshotCopy);
    const newCurrentIndex = updatedHistory.length - 1;
    
    return {
      updatedHistory,
      newCurrentIndex,
    };
  }
}
