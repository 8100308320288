import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';

import App from 'components/App';
import { msalConfig } from 'utils/authConfig';
import store from './redux/store';

import 'styles/main.scss';

const msalInstance = new PublicClientApplication(msalConfig);
const AUTH_ENABLED = process.env.REACT_APP_IS_AUTH_ENABLED;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AppWithStore = (
  <Provider store={store}>
    <App/>
  </Provider>
);

root.render(
  <>
    {
      AUTH_ENABLED ? (
        <MsalProvider instance={msalInstance}>
          {AppWithStore}
        </MsalProvider>
      ) : AppWithStore
    }
  </>
);

reportWebVitals();
