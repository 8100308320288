import Client from 'services/axiosService';
import { AxiosError, AxiosResponse } from 'axios';

const ApiUrl = process.env.REACT_APP_API_URL;

const userProvider = {
  getUserRoles(success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/User/get-user-roles`,
      method: 'get'
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  }
};

export default userProvider;
