import { useEffect } from "react";

export const EXIT_CONFIRMATION_MESSAGE = "Are you sure you want to leave this page?";

export const useConfirmTabClose = (isUnsafeTabClose: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isUnsafeTabClose) {
        event.returnValue = EXIT_CONFIRMATION_MESSAGE;
        return EXIT_CONFIRMATION_MESSAGE;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isUnsafeTabClose]);
};
