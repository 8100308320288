import React from 'react';

const SidebarSection = (
  {
    title,
    children,
  }: {
    title: string;
    children: React.ReactNode;
  }
) => {
  return (
    <div className="mt-3 me-4">
      <h2>{title}</h2>
      {children}
    </div>
  );
}

export default SidebarSection;
