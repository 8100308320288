import React from 'react';

const notFound = () => {
  return (
    <>
      Not Found
    </>
  );
}

export default notFound;
