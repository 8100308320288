import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerReducer } from 'react-router-redux';
import reducers from 'redux/reducers/reducers';
import thunkMiddleware from 'redux-thunk';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const middlewares = [
  // logger,
  thunkMiddleware
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(
  combineReducers({
    routing: routerReducer,
    app: reducers
  }),
  middleware
);

export default store;
