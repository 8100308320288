export type newTemplatePageReducer = {
  sectionIndex: number,
  sections: Array<any>,
  temporaryTableStyle: any,
  temporaryTableData: any,
  newPackData: newPackState | null,
  temporaryStyle: any,
  activeTableIndex: number,
  activeTab: TabTypes,
  changesHistory: any[],
  changesHistoryIndex: number,
  referenceNumber: string,
  temporaryDocumentName: string | null,
  templateName: string
}

export type NotificationFull = {
  id: string,
  label: string,
  text: string,
  type: string
}

export type NotificationTyped = {
  label: string,
  text: string,
  type: string
}

export type Notification = {
  label: string,
  text: string
}

export type AuthData = {
  userName: string,
  name: string,
  bearerToken: string,
  roles: string[]|null
}

export enum packListItemStatus {
  UpToDate = 0,
  Modified = 1,
  FileMissing = 2
}

export type packListItem = {
  name: string,
  id: string,
  user: any,
  status: number,
  modifiedOn: string
}

export type packDetailsData = {
  brand: string,
  createdOn: string,
  documentJson: string,
  documentVersions: string | null,
  assetUrl: string,
  id: string,
  isArchived: boolean,
  isDeleted: boolean,
  modifiedOn: string,
  name: string,
  referenceNum: string,
  sections: Array<packSectionItem>,
  status: number,
  template: string | null,
  templateId: string
}

export type packSectionItem = {
  user: string,
  status: number,
  generationMethod: number,
  json: any,
  documentId: string,
  document: null,
  activityLogs: null,
  id: string,
  name: string,
  modifiedOn: string,
  createdOn: string,
  isDeleted: boolean
}

export type newPackState = {
  brandName: string,
  brandNameValid: boolean,
  brandNameTouched: boolean,
  packModel: string,
  packModelValid: boolean,
  packModelTouched: boolean,
  referenceNumber: string,
  referenceNumberValid: boolean,
  referenceNumberTouched: boolean,
  region: string,
  productGroup: string,
  template: string,
  templateValid: boolean,
  templateTouched: boolean
}

export type activityLogData = {
  value: Array<activityLogItem>
}

export type activityLogItem = {
  user: string,
  action: string,
  sectionId: string,
  id: string,
  modifiedOn: string,
  createdOn: string,
  isDeleted: boolean
}

export type packListLoadData = {
  searchKeyword: string,
  documentStatus: number | null,
  pageSize: number,
  page: number,
  sortColumn: number,
  sortOrder: string
}

export type PriceListLoadData = {
  searchKeyword:string,
  documentStatus: number,
  pageSize: number,
  page: number,
  sortColumn: number,
  sortOrder: string,
  pdfType: number
}

export type CreatePriceListDocumentData = {
  name: string,
  templateId: string,
  validAsOf: string,
  currency: number
  referenceNum: string;
}

export type activityLogLoadData = {
  documentId: string,
  pageSize: number,
  page: number,
  sortColumn: number,
  sortOrder: string
}

export type activityLogRespData = {
  value: {
    activityLog: Array<activityLogItem>,
    totalPages: number,
    pageSize: number,
    page: number
  }
  code: number,
  message: string,
  success: boolean,
  totalPages: number
}


export type packListRespData = {
  value: {
    documents: Array<packListItem>,
    totalPages: number,
    pageSize: number,
    page: number
  },
  code: number,
  message: string,
  success: boolean,
  totalPages: number
}

export type updateDocumentNameData = {
  documentId: string,
  documentName: string
}

export type packSelectData = {
  velue: Array<packSelectItem>
}

export type packSelectItem = {
  createdOn: string,
  id: string,
  isDeleted: boolean,
  modifiedOn: string,
  name: string,
  templates: string | null
}

export type selectOptionsItem = {
  label: string,
  value: string
}

export type backgroundData = {
  background: string,
  filter: string
}

export type authConfig = {
  auth: {
    clientId: string,
    authority: string,
    redirectUri: string,
  },
  cache: {
    cacheLocation: string,
    storeAuthStateInCookie: boolean,
  }
}

export type rgbColor = {
  r: number,
  g: number,
  b: number
}

export enum teamSelection {
  chemical = '0',
  lifesciences = '1'
}

export const teamSelectionStrings = {
  [teamSelection.chemical]: 'Chemical',
  [teamSelection.lifesciences]: 'Life Sciences'
}

export const assetsToNotDisplay = {
  teams: 'Global Assets',
  brand: 'Brand Assets',
  productGroup: 'Product Group Assets',
  region: 'Region Assets',
  family: 'Family Assets',
  color: 'Colour Assets'
}

export type category = {
  id: number,
  name: string
  children: category[]
}
export type createVpModel = {
  brandId: number,
  globalAssetsId: number | null,
  brandAssetsId: number | null,
  productGroupId: number,
  productGroupAssetsId: number | null,
  regionId: number | null,
  regionAssetsId: number | null,
  productFamilyId: number | null,
  productFamilyAssetsId: number | null,
  validationPackId: number,
  referenceNumber: string,
  templateId: string
}
export type templateTableItem = {
  id: string;
  teamName: string | null,
  productGroupName: string | null,
  regionName: string | null,
  templateName: string | null,
  name: string | null
  isBase: boolean
}

export type templateListRespData = {
  value: {
    templates: Array<templateTableItem>,
    totalPages: number,
    pageSize: number,
    page: number
  },
  code: number,
  message: string,
  success: boolean,
  totalPages: number
}
export type templatesListLoadData = {
  searchKeyword: string,
  pageSize: number,
  page: number
}
export enum TabTypes {
  Styles = 'styles',
  Sections = 'sections',
  Tables = 'tables',
}

export type PriceListItem = {
  id: string;
  createdOn: string;
  modifiedOn: string;
  name: string;
  year: number;
  user: string;
  currency: number;
}


export enum UserRoles{
  Admin = 'Role.Admin',
  PriceListTeam = 'Role.PriceListTeam',
  LifeSciencesTeam = 'Role.LifeSciencesTeam',
  ChemicalTeam = 'Role.ChemicalTeam'
}

export type UploadAssetData = {
  title: string;
  path: string;
  file: File;
}

export type UploadedAssetData = {
  id: number;
  name: string;
}

export enum AssetType {
  Image = 0,
  Pdf = 1,
  Xlsx = 2,
}