import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  setActiveSubTab,
  setActiveSubTabItem,
  setIndex,
  addHistorySnapshot,
} from "redux/reducers/newTemplatePage/actionTypes";
import classnames from "classnames";
import SpinnerLoad from "components/shared/spinnerLoad/spinnerLoad";
import ContentSkeleton from "components/pages/newTemplatePage/contentSkeleton";
import AssetBlock from "components/pages/newTemplatePage/infoBlock/assetBlock/assetBlock";
import update from "immutability-helper";
import SectionListItem, { isTitleEditable } from "components/pages/newTemplatePage/sectionBlock/sectionListItem";
import { rearrangeSections, updateSectionName} from "../helpers/sectionListHelpers";

const NUMBER_OF_PREDEFINED_HEAD_ITEMS = 2;
const NUMBER_OF_PREDEFINED_TAIL_ITEMS = 1;

interface SectionBlockProps {
  isPriceList: boolean;
}

const SectionBlock: FunctionComponent<SectionBlockProps> = ({ isPriceList}) => {
  const { loading } = useSelector((state: any) => state.app.newTemplatePage);
  const {
    sectionIndex: activeIndex,
    sections: storeSections,
    isDocumentEditor,
    documentTeam
  } = useSelector((state: any) => state.app.newTemplatePage);

  const dispatch = useDispatch();
  const [sections, setSections] = useState<any>([]);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);


  useEffect(() => {
    // reset active content for sub-tab
    dispatch(setActiveSubTab(null))
    dispatch(setActiveSubTabItem(null))
  }, [dispatch, activeIndex])

  useEffect(() => setSections(storeSections), [storeSections]);

  const handleDeleteItem = (name: string) => { 
    setSections((prevSections:any) => {
      let updatedSections = prevSections.filter((s: any) => s.Name !== name);
      updatedSections = rearrangeSections(updatedSections, documentTeam);
      dispatch(addHistorySnapshot({sections:updatedSections}));
      return updatedSections;
    });
  }

  const handleTitleChange = (itemIndex: number, newTitleValue: string) => {
    setSections((prevSections:any) => {
      const updatedSections = [...prevSections];
      updateSectionName(updatedSections[itemIndex], newTitleValue);
      rearrangeSections(updatedSections, documentTeam);
      dispatch(addHistorySnapshot({sections:updatedSections}));
      return updatedSections;
    });
  }

  const handleHiddenChange = (itemIndex: number, isHidden: boolean) => {
    setSections((prevSections:any) => {
      const updatedSections = [...prevSections];
      updatedSections[itemIndex].Hidden = isHidden;
      rearrangeSections(updatedSections, documentTeam);
      dispatch(addHistorySnapshot({sections:updatedSections}));
      return updatedSections;
    });
  }

  const moveItem = useCallback((dragIndex: number, hoverIndex: number, title: string) => {

    if (hoverIndex < NUMBER_OF_PREDEFINED_HEAD_ITEMS || dragIndex < NUMBER_OF_PREDEFINED_HEAD_ITEMS)
      return;
    
    const tailIndexLimit = sections.length  - 1 - NUMBER_OF_PREDEFINED_TAIL_ITEMS;
    if (hoverIndex > tailIndexLimit || dragIndex > tailIndexLimit)
      return;

    if (!isTitleEditable(title))
      return;

    setHoverIndex(null);
    dispatch(setIndex(-1));
    
    let updatedSections = update(sections, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, sections[dragIndex] as any],
      ],
    });
    updatedSections = rearrangeSections(updatedSections,documentTeam);
    dispatch(addHistorySnapshot({sections: updatedSections}));

  }, [dispatch, sections]);

  const renderCard = useCallback(
    (item: { Name: string }, index: number) => {
      return (
        <SectionListItem
          handleHiddenChange={handleHiddenChange}
          handleTitleChange={handleTitleChange}
          isPriceList={isPriceList}
          key={item.Name}
          index={index}
          id={item.Name}
          item={item}
          moveCard={moveItem}
          className={classnames({ "pt-3": hoverIndex && hoverIndex === index })}
          handleDeleteItem={handleDeleteItem}
        />
      )
    },
    [sections]);

  const sectionList = sections.length ? (
    <DndProvider backend={HTML5Backend}>
      {sections.map((item: any, index: number) => renderCard(item, index))}
    </DndProvider>
  ) : null

  return (
    <ContentSkeleton
      sidebar={
        <ul className="section-list border-start-0 font-14 m-0 p-0">
          {
            loading
              ? <SpinnerLoad className="d-flex justify-content-center mt-5" size={30}/>
              : sectionList
          }
        </ul>
      }
      content={isDocumentEditor ? <AssetBlock isPriceList={isPriceList} /> : null}
    />
  )
}

export default SectionBlock;
