import { FunctionComponent } from 'react';
import { FaFolder } from 'react-icons/fa';
import { BsFileEarmarkText, BsPlusLg } from 'react-icons/bs';
import NavItem from './frameSideNavItem';
import { isPriceListManager, isValidationPackManager } from "services/RoleService";

const NAV_ICON_SIZE = 20;

const FrameSideNav: FunctionComponent = () => {
  
  return (
    <div className="frame-side bg-light-blue position-fixed">
      <div className="text-center">
        <img
          className="frame-nav-logo pt-2 mb-4 mx-auto"
          src="/img/ansell-logo.png"
          alt="ansell_logo"
        />
      </div>

      {isValidationPackManager() && (
        <NavItem
          className="frame-icon mt-3 c-pointer"
          path="/"
          withChildren={["/document-details", "/editor"]}
          toolTipText="Validation Packs"
          icon={<FaFolder size={NAV_ICON_SIZE} />}
        />
      )}
      {isValidationPackManager() && (
        <NavItem
          className="frame-icon c-pointer"
          path="/add-pack"
          withChildren={["/add-pack"]}
          toolTipText="Add New Validation Pack"
          icon={<BsPlusLg size={NAV_ICON_SIZE} />}
        />
      )}
      {isValidationPackManager() && (
        <NavItem
          className="frame-icon c-pointer"
          path="/templates"
          withChildren={["/new-template-editor"]}
          toolTipText="Validation Pack Templates"
          icon={<BsFileEarmarkText size={NAV_ICON_SIZE} />}
        />
      )}

      <div className="nav-divider" />

      {isPriceListManager() && (
        <NavItem
          className="frame-icon mt-3 c-pointer"
          path="/price-lists"
          withChildren={["/price-list-document-details", "/price-list-editor/"]}
          toolTipText="Price Lists"
          icon={<FaFolder size={NAV_ICON_SIZE} />}
        />
      )}
      {isPriceListManager() && (
        <NavItem
          className="frame-icon c-pointer"
          path="/add-price"
          toolTipText="Add New Price List"
          icon={<BsPlusLg size={NAV_ICON_SIZE} />}
        />
      )}
      {isPriceListManager() && (
        <NavItem
          className="frame-icon c-pointer"
          path="/price-templates"
          withChildren={["/price-list-editor-template-editor"]}
          toolTipText="Price List Templates"
          icon={<BsFileEarmarkText size={NAV_ICON_SIZE} />}
        />
      )}
    </div>
  );
}

export default FrameSideNav;
