import {newPackState, TabTypes} from 'types';
import { ICategory } from "redux/reducers/newTemplatePage/reducer";

export const ADD_HISTORY_SNAPSHOT = 'ADD_HISTORY_SNAPSHOT';

interface AddHistorySnapshotParams {
  tableData?: any,
  tableStyle?: any,
  sections?: any,
  styles?: any,
  refNumber?: string,
  docName?:string,
  templateName?: string,
  activeTab?: TabTypes | undefined
}

export function addHistorySnapshot(values: AddHistorySnapshotParams) {
  return {
    type: ADD_HISTORY_SNAPSHOT,
    payload: values
  };
}

export const SET_PREVIOUS_SNAP = 'SET_PREVIOUS_SNAP';

export function setPreviousSnap() {
  return {
    type: SET_PREVIOUS_SNAP,
    payload: undefined
  };
}

export const SET_NEXT_SNAP = 'SET_NEXT_SNAP';

export function setNextSnap() {
  return {
    type: SET_NEXT_SNAP,
    payload: undefined
  };
}

export const SET_ACTIVE_TABLE_INDEX = 'SET_ACTIVE_TABLE_INDEX';

export function setActiveTableIndex(index: number) {
  return {
    type: SET_ACTIVE_TABLE_INDEX,
    payload: index
  };
}

export const SET_LOADING = 'SET_LOADING';

export function setLoading(loading: boolean) {
  return {
    type: SET_LOADING,
    payload: loading
  };
}

export const SET_IS_PAGE_LOADING = 'SET_IS_PAGE_LOADING';

export function setIsPageLoading(loading: boolean, text: string | null = null) {
  return {
    type: SET_IS_PAGE_LOADING,
    payload: {
      loading,
      text
    }
  };
}

export const SET_TEMPLATE_NAME = 'SET_TEMPLATE_NAME';

export function setTemplateName(value: string) {
  return {
    type: SET_TEMPLATE_NAME,
    payload: value
  };
}

export const SET_SECTIONS = 'SET_SECTIONS';

export function setTempSections(sectionsData: Array<any>) {
  return {
    type: SET_SECTIONS,
    payload: sectionsData
  };
}

export const SET_PRIMARY_LOGO_WHITE = 'SET_PRIMARY_LOGO_WHITE';

export function setPrimaryLogoWhite(data: any) {
  return {
    type: SET_PRIMARY_LOGO_WHITE,
    payload: data
  };
}

export const SET_PRIMARY_LOGO_BLUE = 'SET_PRIMARY_LOGO_BLUE';

export function setPrimaryLogoBlue(data: any) {
  return {
    type: SET_PRIMARY_LOGO_BLUE,
    payload: data
  };
}

export const SET_INDEX = 'SET_INDEX';

export function setIndex(newIndex: number) {
  return {
    type: SET_INDEX,
    payload: newIndex
  };
}


export const CLEAR_DATA = 'CLEAR_DATA';

export function clearData() {
  return {
    type: CLEAR_DATA
  };
}

export const SET_SUBCATEGORIES = 'SET_SUBCATEGORIES';

export function setSubcategories(subcategories: ICategory[]) {
  return {
    type: SET_SUBCATEGORIES,
    payload: subcategories
  };
}

export const SET_IS_DOCUMENT_EDITOR = 'SET_IS_DOCUMENT_EDITOR';

export function setIsDocumentEditor(isEditor: boolean) {
  return {
    type: SET_IS_DOCUMENT_EDITOR,
    payload: isEditor
  };
}

export const SET_HAS_UNSAVED_CHANGES = 'SET_HAS_UNSAVED_CHANGES';

export function setHasUnsavedChanges(value: boolean) {
  return {
    type: SET_HAS_UNSAVED_CHANGES,
    payload: value
  };
}

export const SET_SHOW_UNSAVED_CHANGED_MODAL = 'SET_SHOW_UNSAVED_CHANGED_MODAL';

export function setShowUnsavedChangedModal(value: boolean) {
  return {
    type: SET_SHOW_UNSAVED_CHANGED_MODAL,
    payload: value
  };
}

export const SET_PACK_DATA = 'SET_PACK_DATA';

export function setPackData(data: newPackState) {
  return {
    type: SET_PACK_DATA,
    payload: data
  };
}

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export function setActiveTab(tab: string) {
  return {
    type: SET_ACTIVE_TAB,
    payload: tab
  };
}

export const SET_ACTIVE_SUB_TAB = 'SET_ACTIVE_SUB_TAB';

export function setActiveSubTab(tab: any) {
  return {
    type: SET_ACTIVE_SUB_TAB,
    payload: tab
  };
}

export const SET_ACTIVE_SUB_TAB_ITEM = 'SET_ACTIVE_SUB_TAB_ITEM';

export function setActiveSubTabItem(item: string | null) {
  return {
    type: SET_ACTIVE_SUB_TAB_ITEM,
    payload: item
  };
}

export const SET_TEMP_STYLE = 'SET_TEMP_STYLE';

export function setTempStyle(payload: any) {
  return {
    type: SET_TEMP_STYLE,
    payload: payload
  };
}

export const SET_TEMP_TABLE_STYLE = 'SET_TEMP_TABLE_STYLE';

export function setTempTableStyle(payload: any) {
  return {
    type: SET_TEMP_TABLE_STYLE,
    payload: payload
  };
}

export const SET_TEMP_TABLE_DATA = 'SET_TEMP_TABLE_DATA';

export function setTempTableData(payload: any) {
  return {
    type: SET_TEMP_TABLE_DATA,
    payload: payload
  };
}

export const SET_ORIGINAL_TABLE_DATA = 'SET_ORIGINAL_TABLE_DATA';

export function setOriginalTableData(payload: any) {
  return {
    type: SET_ORIGINAL_TABLE_DATA,
    payload: payload
  };
}

export const SET_PLANNED_PATH = 'SET_PLANNED_PATH';

export function setPlannedPath(payload: string | null){
  return {
    type: SET_PLANNED_PATH,
    payload: payload
  }
}

export const SET_DOCUMENT_TEAM = 'SET_DOCUMENT_TEAM';

export function setDocumentTeam(payload: string | null){
  return {
    type: SET_DOCUMENT_TEAM,
    payload: payload
  }
}

interface SetDocumentTeam{
  type: typeof SET_DOCUMENT_TEAM,
  payload: string
}

interface AddHistorySnapshot {
  type: typeof ADD_HISTORY_SNAPSHOT,
  payload: any
}

interface SetPreviousSnap {
  type: typeof SET_PREVIOUS_SNAP,
  payload: any
}

interface SetNextSnap {
  type: typeof SET_NEXT_SNAP,
  payload: any
}

interface SetActiveTableIndex{
  type: typeof SET_ACTIVE_TABLE_INDEX,
  payload: number
}

interface SetLoading {
  type: typeof SET_LOADING,
  payload: boolean
}

interface SetIsPageLoading {
  type: typeof SET_IS_PAGE_LOADING,
  payload: {
    loading: boolean,
    text: string | null
  }
}

interface SetTempSections {
  type: typeof SET_SECTIONS,
  payload: Array<any>
}

interface SetPrimaryLogoWhite {
  type: typeof SET_PRIMARY_LOGO_WHITE,
  payload: Array<any>
}

interface SetPrimaryLogoBlue {
  type: typeof SET_PRIMARY_LOGO_BLUE,
  payload: Array<any>
}

interface SetTemplateName {
  type: typeof SET_TEMPLATE_NAME,
  payload: string
}

interface SetIndex {
  type: typeof SET_INDEX,
  payload: number
}

interface ClearData {
  type: typeof CLEAR_DATA
}

interface SetSubcategories {
  type: typeof SET_SUBCATEGORIES,
  payload: ICategory[],
}

interface SetIsDocumentEditor {
  type: typeof SET_IS_DOCUMENT_EDITOR,
  payload: boolean
}

interface SetHasUnsavedChanges {
  type: typeof SET_HAS_UNSAVED_CHANGES,
  payload: boolean
}

interface SetShowUnsavedChangedModal {
  type: typeof SET_SHOW_UNSAVED_CHANGED_MODAL,
  payload: boolean
}

interface SetPackData {
  type: typeof SET_PACK_DATA,
  payload: newPackState
}

interface SetActiveTab {
  type: typeof SET_ACTIVE_TAB,
  payload: string
}

interface SetActiveSubTab {
  type: typeof SET_ACTIVE_SUB_TAB,
  payload: any
}

interface SetActiveSubTabItem {
  type: typeof SET_ACTIVE_SUB_TAB_ITEM,
  payload: string
}

interface SetTempStyle {
  type: typeof SET_TEMP_STYLE,
  payload: any
}

interface SetTempTableStyle {
  type: typeof SET_TEMP_TABLE_STYLE,
  payload: any
}

interface SetTempTableData {
  type: typeof SET_TEMP_TABLE_DATA,
  payload: any
}

interface SetOriginalTableData {
  type: typeof SET_ORIGINAL_TABLE_DATA,
  payload: any
}

interface SetPlannedPath {
  type: typeof SET_PLANNED_PATH,
  payload: string | null
}

export type NewTemplatePageActionTypes = (
    AddHistorySnapshot
  | SetPreviousSnap
  | SetNextSnap
  | SetTempSections
  | SetPrimaryLogoWhite
  | SetPrimaryLogoBlue
  | SetSubcategories
  | SetIndex
  | ClearData
  | SetIsDocumentEditor
  | SetHasUnsavedChanges
  | SetShowUnsavedChangedModal
  | SetTemplateName
  | SetPackData
  | SetLoading
  | SetIsPageLoading
  | SetActiveTab
  | SetTempStyle
  | SetTempTableStyle
  | SetTempTableData
  | SetOriginalTableData
  | SetActiveSubTab
  | SetActiveSubTabItem
  | SetActiveTableIndex
  | SetPlannedPath
  | SetDocumentTeam
  );
