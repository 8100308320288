import React, { FunctionComponent, Suspense } from 'react';
import FrameTopBar from './frameTopBar/frameTopBar';
import FrameSideNav from './frameSideNav/frameSideNav';
import SuspenseLoader from 'components/shared/suspenseLoader/suspenseLoader';
import PageLoad, { GENERATING_TEXT } from "components/shared/spinnerLoad/pageLoad";
import { useSelector } from "react-redux";
import './appFrame.scss';
import Router from "router";

const AppFrame: FunctionComponent = () => {
  const {
    isPageLoadingActive,
    pageLoadingText
  } = useSelector((state: any) => state.app.newTemplatePage);

  return (
    <div className="app-frame">
      {
        isPageLoadingActive && <PageLoad loadingText={pageLoadingText || GENERATING_TEXT}/>
      }
      <FrameSideNav/>
      <FrameTopBar/>
      <Suspense fallback={<SuspenseLoader/>}>
        <div className="frame-content">
          <div className="h-100">
            <Router/>
          </div>
        </div>
      </Suspense>
    </div>
  );
}

export default AppFrame;
