import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toast } from "react-toastify";

const PdfViewer = ({ pdfUrl }: { pdfUrl: string }) => {
  if (!pdfUrl) {
    toast.warning('Failure opening PDF')
    return null
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
      <Viewer fileUrl={pdfUrl} />
    </Worker>
  );
}

export default PdfViewer;
