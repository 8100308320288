import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import ProfileSettingsModal from './profileSettingsModal';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const AUTH_ENABLED = process.env.REACT_APP_IS_AUTH_ENABLED === 'true';

const UserBlock: FunctionComponent = () => {
  const navigate = useNavigate();

  const [ popupFadeTimeout, setPopupFade ] = useState<any>(null);
  const [ popupOpen, setPopup ] = useState<boolean>(false);
  const [ profileModalOpen, setProfileModal ] = useState<boolean>(false);
  const username = useSelector((state: any) => state.app.auth.username);
  const fullName = useSelector((state: any) => state.app.auth.name);
  const bearer = useSelector((state: any) => state.app.auth.bearerToken);
  const isAuthenticated = useIsAuthenticated();

  const { instance } = useMsal();


  useEffect(()=>{
    if(AUTH_ENABLED) {
      if (!bearer) {
        if (isAuthenticated) {
          handleLogout();
        } else {
          navigate('/login');
        }
      }
    }
  },[bearer])

  function handleLogout() {
    instance.logoutPopup().then(() => {
      navigate('/login');
    }).catch((e: any) => {
      console.error(e);
    });
  }


  const popupFadeHandler = () => {
    const timeToFade = 1500;

    if (popupFadeTimeout) {
      clearTimeout(popupFadeTimeout);
    }

    setPopupFade(setTimeout(() => {
      setPopup(false);
    }, timeToFade));
  };

  const showName = fullName ? fullName : (username ? username : 'Guest');
  return (
    <>
      <div
        className="user-block position-relative c-pointer d-flex justify-content-start align-items-center"
        onClick={() => setPopup(!popupOpen)}
        onMouseMove={popupFadeHandler}
      >
        <p className="m-0 font-14">{showName}</p>

        <div className={classnames('user-popup position-absolute p-2 rounded', { 'd-none': !popupOpen })}>
          <p className="user-popup-option mb-0" onClick={handleLogout}>
            Log Out
          </p>
        </div>
      </div>

      <ProfileSettingsModal
        isOpen={profileModalOpen}
        closeModal={() => setProfileModal(false)}
      />
    </>
  );
};

export default UserBlock;
