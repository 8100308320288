import { AuthData } from 'types';

export const SET_AUTH_DATA = 'SET_AUTH_DATA';

export function setAuthData(data: AuthData) {
  return {
    type: SET_AUTH_DATA,
    payload: data
  };
}

export const SET_USER_ROLES = 'SET_USER_ROLES';

export function setUserRoles(data: string[]|null) {
  return {
    type: SET_USER_ROLES,
    payload: data
  };
}

export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';

export function clearAuthData() {
  return {
    type: CLEAR_AUTH_DATA
  };
}

interface SetAuthData {
  type: typeof SET_AUTH_DATA,
  payload: AuthData
}

interface SetUserRoles {
  type: typeof SET_USER_ROLES,
  payload: string[]|null
}

interface ClearAuthData {
  type: typeof CLEAR_AUTH_DATA,
  payload: null
}

export type AuthActionTypes = SetAuthData | ClearAuthData | SetUserRoles;
