import { useState } from "react";
import { GrRedo, GrUndo } from "react-icons/gr";
import { Tooltip } from "reactstrap";
import { useSelector } from "react-redux";

type HistoryControllerProps = {
  back: () => void;
  forward: () => void;
};

export const HistoryController = ({back, forward}: HistoryControllerProps) => {

  const [prevTooltip, setPrevTooltip] = useState<boolean>(false);
  const [nextTooltip, setNextTooltip] = useState<boolean>(false);

  const {
    changesHistory: {length: historyLength},
    changesHistoryIndex,
  } = useSelector((state: any) => state.app.newTemplatePage);

  const shouldHideUndo = () => changesHistoryIndex === 0;
  const shouldHideRedo = () =>  changesHistoryIndex === historyLength - 1;

  return (
    <div className="mt-2 ms-4 btn-group" role="group">
      <button
        id="goToPreviousButton"
        className='border border-2 btn btn-outline-primary border-primary'
        onClick={back}
        disabled={shouldHideUndo()}
      >
        <GrUndo size={20} />
      </button>
      <Tooltip
        placement="bottom"
        isOpen={prevTooltip}
        target="goToPreviousButton"
        toggle={() => setPrevTooltip(!prevTooltip)}
      >
        Undo
      </Tooltip>
      <button
        id="goToNextButton"
        className='border border-2 btn btn-outline-primary border-primary'
        onClick={forward}
        disabled={shouldHideRedo()}
      >
        <GrRedo size={20} />
      </button>
      <Tooltip
        placement="bottom"
        isOpen={nextTooltip}
        target="goToNextButton"
        toggle={() => setNextTooltip(!nextTooltip)}
      >
        Redo
      </Tooltip>
    </div>
  );
};
