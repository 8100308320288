import packsProvider from 'providers/packsProvider';
import { UploadAssetData, activityLogLoadData, packListLoadData, templatesListLoadData, updateDocumentNameData } from 'types';
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

const packsService = {
  getPage(data: packListLoadData, success: Function, error?: Function) {
    packsProvider.getPage(data, success, error);
  },
  getPack(id: string, success: Function, error?: Function) {
    packsProvider.getPack(id, success, error);
  },
  updatePack(documentId: string, documentJson: string, success: Function, error?: Function) {
    const data = {
      documentId,
      documentJson
    };
    packsProvider.updatePack(data, success, error);
  },
  getPackDetails(id: string, success: Function, error?: Function) {
    packsProvider.getPackDetails(id, success, error);
  },
  getActivityLogPage(data: activityLogLoadData, success: Function, error?: Function) {
    packsProvider.getActivityLogPage(data, success, error);
  },
  getTemplateDetails(id: string, success: Function, error?: Function) {
    packsProvider.getTemplateDetails(id, success, error);
  },
  getPackTemplates(teamName: string, productGroupName: string, regionName: string, success: Function, error?: Function) {
    packsProvider.getPackTemplates(teamName, productGroupName, regionName, success, error);
  },
  createAndPreviewDocument(model: any, success: Function, error?: Function) {
    packsProvider.createAndPreviewDocument(model, success, error);
  },
  archiveDocument(documentId: string, success: Function, error?: Function) {
    packsProvider.archiveDocument(documentId, success, error);
  },
  getAssetById(assetId: number, success: Function, error?: Function) {
    packsProvider.getAssetById(assetId, success, error);
  },
  approveAssetById(assetId: number, documentId: string, success: Function, error?: Function) {
    packsProvider.approveAssetById(assetId, documentId, success, error);
  },
  generateDocument(documentId: string, team: number, docTitle: string, success: Function, error?: Function) {
    packsProvider.generateDocument(documentId, team, docTitle, success, error);
  },
  regenerateDocument(documentId: string, success: Function, error?: Function) {
    packsProvider.regenerateDocument(documentId, success, error);
  },
  getSubcategoriesFromAssetbank(categoryId: number | null, success: Function, error?: Function) {
    packsProvider.getSubcategoryOfAssetBank(categoryId, success, error);
  },
  getSearchAssetBankFiles(title: string, categoryId: number | null, assetType: number | null, success: Function, error?: Function) {
    packsProvider.searchAssetBankFiles(title, categoryId, assetType, success, error);
  },
  getSearchAssetBank(title: string, categoryId: number | null, success: Function, error?: Function) {
    packsProvider.searchAssetBank(title, categoryId, success, error);
  },
  updateDocumentStatuses(documentId: string, success: Function, error?: Function) {
    packsProvider.updateDocumentStatuses(documentId, success, error);
  },
  getTemplatesPage(data: templatesListLoadData, success: Function, error?: Function) {
    packsProvider.getTemplatesPage(data, success, error);
  },
  updateTemplate(data: any, success: Function, error?: Function) {
    packsProvider.updateTemplate(data, success, error);
  },
  deleteTemplate(templateId: string, success: Function, error?: Function) {
    packsProvider.deleteTemplate(templateId, success, error);
  },
  duplicateTemplate(templateId: string, success: Function, error?: Function) {
    packsProvider.duplicateTemplate(templateId, success, error);
  },
  handleGenerationErrors(res: AxiosResponse) {
    if (res.headers.errormessages && res.headers.errormessages.length) {
      const errors = JSON.parse(res.headers.errormessages);

      errors.slice(0, 5).forEach((err: string) => {
        toast.info(err, {
          autoClose: 10000,
          pauseOnHover: true,
        });
      })
    }
  },
  uploadAsset(data: UploadAssetData, success: Function, error?: Function){
    packsProvider.uploadAsset(data, success, error);
  }
}

export default packsService;
