import React from 'react';

const SidebarSubSection = (
  {
    title,
    children,
  }: {
    title: string;
    children: React.ReactNode;
  }
) => {
  return (
    <div className="py-4">
      {/* <h3 className="text-lg font-semibold mb-4">{title}</h3> */}
      {children}
    </div>
  );
}

export default SidebarSubSection;
