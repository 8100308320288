import React, {useEffect, useState} from 'react';
import {Input} from "reactstrap";
import {InputProps} from "reactstrap/types/lib/Input";

interface DelayedTextFieldProps extends InputProps {
    initialValue: string;
    onChanged: (value: string) => void;
}

const DelayedTextField: React.FC<DelayedTextFieldProps> = ({initialValue, onChanged, ...props}) => {

    const [tempValue, setTempValue] = useState<string>('');
    useEffect(() => setTempValue(initialValue), [initialValue]);

    const handleBlur = (value: string) => {
      if(value.trim() === '')
          setTempValue(initialValue);
      else if (initialValue !== value)
        onChanged(value);
    };

    return (
        <Input
            onKeyDown={(e) => e.stopPropagation()}
            value={tempValue}
            onChange={(e) => setTempValue(e.target.value)}
            onBlur={(e) => handleBlur(e.target.value)}
            {...props}
        />
    );
};

export default DelayedTextField;
