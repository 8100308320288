import { FunctionComponent } from 'react';
import Spinner from '../spinnerLoad/spinnerLoad';

const SuspenseLoader: FunctionComponent = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}
         className="d-flex align-items-center justify-content-center"
    >
      <Spinner className="light" size={50}/>
    </div>
  );
}

export default SuspenseLoader;
