import { FunctionComponent } from 'react';
import { backgroundData } from 'types';
import classnames from 'classnames';

import './backgroundColor.scss';

type BackgroundColorProps = {
  backgroundData: backgroundData,
  className?: string,
  bodyClassName?: string,
  children: any
}

const BackgroundColor: FunctionComponent<BackgroundColorProps> = (props) => {
  const { backgroundData, className, bodyClassName, children } = props;

  return (
    <div className={classnames("background-colors position-relative", className)}>
      <div className={"background-colors-plane position-absolute"}
           style={{ background: backgroundData.background, filter: backgroundData.filter }}/>
      <div className={classnames("background-colors-content position-absolute", bodyClassName)}>
        {children}
      </div>
    </div>
  );
};

export default BackgroundColor;
