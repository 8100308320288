import React, { MouseEvent, useEffect, useState } from "react";
import { BiHide, BiShowAlt } from "react-icons/bi";
import { Tooltip } from "reactstrap";
import classnames from "classnames";

interface SectionVisibilitySwitcherProps {
  onSwitched: (value: boolean) => void;
  isItemHidden: boolean;
  size: number;
  className?: string;
}

const SectionVisibilitySwitcher: React.FC<SectionVisibilitySwitcherProps> = ({
  onSwitched,
  isItemHidden,
  size,
  className,
}) => {
  const [showHint, setShowHint] = useState<boolean>(false);

  const switchValue = (e: MouseEvent) => {
    e.stopPropagation();
    onSwitched(!isItemHidden);
  };

  return (
    <>
      <BiHide
        id="hiddenSectionIcon"
        size={size}
        className={classnames(className, { "d-none": !isItemHidden })}
        onClick={switchValue}
      />
      <BiShowAlt
        id="visibleSectionIcon"
        size={size}
        className={classnames(className, { "d-none": isItemHidden })}
        onClick={switchValue}
      />
      <Tooltip
        placement="top"
        isOpen={showHint}
        target={isItemHidden ? "hiddenSectionIcon" : "visibleSectionIcon"}
        toggle={() => setShowHint(!showHint)}
      >
        {isItemHidden ? "Hidden" : "Visible"}
      </Tooltip>
    </>
  );
};

export default SectionVisibilitySwitcher;
