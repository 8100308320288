import { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import AppFrame from 'components/pages/appFrame/appFrame';
import LoginPage from 'components/pages/loginPage/loginPage';
import LandingPage from 'components/pages/landingPage/landingPage';
import { loginRequest } from 'utils/authConfig';
import { clearAuthData, setAuthData, setUserRoles } from 'redux/reducers/auth/actionTypes';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerLoad from './shared/spinnerLoad/spinnerLoad';
import userService from '../services/userService';

const AUTH_ENABLED = process.env.REACT_APP_IS_AUTH_ENABLED === 'true';

const App: FunctionComponent = () => {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const bearer = useSelector((state: any) => state.app.auth.bearerToken);
  const roles = useSelector((state: any) => state.app.auth.roles);
  const name = accounts[0] && accounts[0].name ? accounts[0].name : 'Guest';
  const userName = accounts[0] && accounts[0].username ? accounts[0].username : 'Guest';

  const setAccountInfo = (accessToken: string) => {
    dispatch(setAuthData({
      name,
      userName,
      bearerToken: accessToken,
      roles: null
    }));
  };

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0]
      };
      instance.acquireTokenSilent(request).then((response: any) => {
        setAccountInfo(response.accessToken);
      }).catch((e: any) => {
        instance.acquireTokenPopup(request).then((response: any) => {
          setAccountInfo(response.accessToken);
        });
      });
      instance.getAccountByUsername(userName);
    } else {
      dispatch(clearAuthData());
    }
  }, [ dispatch, isAuthenticated ]);

  useEffect(() => {
    if (!!bearer) {
      userService.getUserRoles((result: any) => {
        if (result?.data?.value) {
          dispatch(setUserRoles(result.data.value));
        } else {
          dispatch(setUserRoles([]));
        }
      }, () => {
        dispatch(setUserRoles(null));
        toast.error('Can\'t get your role.');
      });
    }
  }, [ bearer ]);

  const isAuth = !AUTH_ENABLED || (!!bearer && roles != null && isAuthenticated);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Routes>
          {isAuth &&
            <Route
              path={'*'}
              element={<AppFrame/>}
            />
          }
          {isAuth &&
            <Route path={'login'} element={<Navigate to={`/`} replace/>}/>
          }
          {AUTH_ENABLED && (!bearer || roles == null) && isAuthenticated &&
            <Route
              path={'*'}
              element={<SpinnerLoad className="d-flex justify-content-center mt-4" size={80}/>}
            />
          }
          {AUTH_ENABLED && !isAuthenticated &&
            <Route
              path={`login`}
              element={<LoginPage/>}
            />
          }
          <Route
            path={'landing'}
            element={<LandingPage/>}
          />
          {AUTH_ENABLED && !isAuthenticated &&
            <Route path={'*'} element={<Navigate to={`login`} replace/>}/>
          }
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
