import { FunctionComponent } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type NewStyleModalProps = {
  isOpen: boolean,
  closeModal: Function
}

const ProfileSettingsModal: FunctionComponent<NewStyleModalProps> = ({ isOpen, closeModal }) => {
  return (
    <Modal isOpen={isOpen} toggle={() => closeModal()}>
      <ModalHeader toggle={() => closeModal()}>Profile Settings</ModalHeader>
      <ModalBody>


      </ModalBody>
      <ModalFooter>
        <Button className="text-white bg-primary fw-bold" onClick={() => closeModal()}>
          Save
        </Button>
        <Button className="text-white bg-primary fw-bold" onClick={() => closeModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ProfileSettingsModal;
