import store from 'redux/store';
import { UserRoles } from 'types';

const AUTH_ENABLED = process.env.REACT_APP_IS_AUTH_ENABLED === 'true';

export const isAdmin = () => {
  const roles = store.getState().app.auth.roles;
  if(!AUTH_ENABLED)
    return true;
  if (roles == null)
    return false;
  return roles.includes(UserRoles.Admin);
};

export const isValidationPackManager = () => {
  const roles = store.getState().app.auth.roles;
  if(!AUTH_ENABLED)
    return true;
  if (roles == null)
    return false;
  return roles.includes(UserRoles.LifeSciencesTeam) || roles.includes(UserRoles.ChemicalTeam) || isAdmin();
};

export const isPriceListManager = () => {
  const roles = store.getState().app.auth.roles;
  if(!AUTH_ENABLED)
    return true;
  if (roles == null)
    return false;
  return roles.includes(UserRoles.PriceListTeam) || isAdmin();
};
