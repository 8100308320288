import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button, Input } from 'reactstrap';
import { useMsal } from '@azure/msal-react';

import BackgroundColor from 'components/shared/backgroundColor/backgroundColor';
import { loginRequest } from 'utils/authConfig';

import './loginPage.scss';


const LoginPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleSSOLoginPopUp = () => {
    instance.loginPopup(loginRequest).then(e => {
      navigate('/');
    }).catch(e => {
      console.error(e);
    });
  };

  return <Row className="login-page">
    <Col xs={12} md={7} className="p-0">
      <BackgroundColor className="w-100 h-100" bodyClassName="d-flex align-items-center justify-content-center"
                       backgroundData={{ background: 'url(/img/login-background.png)', filter: 'opacity(0.1)' }}
      >
        <div className="text-center">
          <img className="company-logo mb-3" src="/img/ansell-logo.png" alt="ansell-logo"/>
          <h1>Automatic Validation Pack Generation System</h1>
        </div>
      </BackgroundColor>
    </Col>
    <Col className="d-flex align-items-center justify-content-center p-0" xs={12} md={5}>
      <div className="form-container text-black w-100 px-5">
        <p className="mb-0">Welcome back</p>
        <h1>Login to your account</h1>

        <hr className="my-4"/>

        <p>Ansell and External User with SSO Enabled</p>
        <Button className="text-white bg-primary w-100 fw-bold py-3 mb-4" onClick={handleSSOLoginPopUp}>
          Login for SSO
        </Button>
      </div>
    </Col>
  </Row>;
};

export default LoginPage;
