import React from 'react';
import { StyleType } from "components/pages/newTemplatePage/helpers/types";
import { Form, Formik } from "formik";
import { defaultAlignment, defaultFonts } from "services/DataService";
import SidebarSection from "components/pages/newTemplatePage/tableBlock/sidebarSection";
import SidebarSubSection from "components/pages/newTemplatePage/tableBlock/sidebarSubSection";
import StylingBlock from "components/pages/newTemplatePage/tableBlock/stylingBlock";
import BlockField from "components/pages/newTemplatePage/tableBlock/blockField";

interface StyleCustomizerProps {
  style: StyleType;
  setStyle: React.Dispatch<React.SetStateAction<StyleType>>;
  defaultStyle: StyleType;
}



const StyleCustomizer = ({ style, setStyle, defaultStyle}: StyleCustomizerProps) => {
  
  if (!defaultStyle?.style) 
    return null;

  const initialValues = {
    styles: style,
  };

  return (
    <SidebarSection title="Style">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setStyle(values.styles);
        }}
        validateOnChange={true}
        enableReinitialize={true}
        validate={(values) => {
          if (JSON.stringify(values.styles) !== JSON.stringify(initialValues.styles)) {
            setStyle(values.styles);
          }
          return {};
        }}
      >
        {({ values }) => (
          <Form>
            <SidebarSubSection title="General">
              <StylingBlock title="Table (both Header and Cells)">
                <BlockField
                  title="Font Family"
                  inputName="styles.style.general.fontName"
                  inputType="select"
                  defaultValue={defaultStyle.style.general.fontName}
                  selectOptions={defaultFonts}
                />
              </StylingBlock>

              <StylingBlock title="Headers">
                <BlockField
                  title="Font Size"
                  inputName="styles.style.headers.fontSize"
                  inputType="number"
                  defaultValue={defaultStyle.style.headers.fontSize}
                />
                <BlockField
                  title="Text Alignment"
                  inputName="styles.style.headers.horizontalAlignment"
                  inputType="select"
                  defaultValue={defaultStyle.style.headers.horizontalAlignment}
                  selectOptions={defaultAlignment}
                />
              </StylingBlock>

              <StylingBlock title="Cells">
                <BlockField
                  title="Font Size"
                  inputName="styles.style.rows.fontSize"
                  inputType="number"
                  defaultValue={defaultStyle.style.rows.fontSize}
                />
                <BlockField
                  title="Text Alignment"
                  inputName="styles.style.rows.horizontalAlignment"
                  inputType="select"
                  defaultValue={defaultStyle.style.rows.horizontalAlignment}
                  selectOptions={defaultAlignment}
                />
              </StylingBlock>
            </SidebarSubSection>
          </Form>
        )}
      </Formik>
    </SidebarSection>
  );
}

export default StyleCustomizer;
