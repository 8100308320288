import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import BlockField from "components/pages/newTemplatePage/tableBlock/blockField";
import {
  addHistorySnapshot,
  setPrimaryLogoBlue,
  setPrimaryLogoWhite,
  setSubcategories,
  setTempSections
} from "redux/reducers/newTemplatePage/actionTypes";
import packsService from "services/packsService";
import AsyncSelect from "react-select/async";
import SpinnerLoad from "components/shared/spinnerLoad/spinnerLoad";
import { debounce } from 'lodash';
import { ImLocation } from "react-icons/im";
import { ICategory } from "redux/reducers/newTemplatePage/reducer";
import PdfViewer from "components/shared/PDFViewer/PdfViewer";
import './assetBlock.scss';
import { TITLE_SUB_ITEMS } from "components/pages/newTemplatePage/sectionBlock/sectionListItem";
import { toast } from "react-toastify";
import CustomCheckbox from 'components/shared/customCheckbox/customCheckbox';
import { Button} from 'reactstrap';
import AssetUploadModal from 'components/shared/assetUploadModal/assetUploadModal';
import { AssetType, UploadedAssetData } from 'types';

interface ISelectedValue {
  id: number,
  name: string
}



interface AssetBlockProps {
  isPriceList: boolean
}

const AssetBlock: FunctionComponent<AssetBlockProps> = ({ isPriceList }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string | null>(null);
  const [blob, setBlob] = useState<any>(null);
  const [isPdf, setIsPdf] = useState<boolean | null>(false);
  const [isContentExists, setIsContentExists] = useState<boolean>(false);
  const [fileIsMandatory, setFileIsMandatory] = useState<any>(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<ISelectedValue | null>(null);
  const [isWatermarked, setIsWatermarked] = useState<boolean | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUploadPath, setCurrentUploadPath] = useState<string | null>(null);
  const [assetType, setAssetType] = useState<AssetType | null>(null);
  const {
    activeSubTab,
    activeSubTabItem,
    sections,
    subcategories,
    primaryLogoWhite,
    primaryLogoBlue,
    sectionIndex: activeSectionIndex
  } = useSelector((state: any) => state.app.newTemplatePage);

  const isTitleSectionBrandProductActive = activeSubTabItem === TITLE_SUB_ITEMS.brandProduct;
  const isTitleSectionBrandLogoActive = activeSubTabItem === TITLE_SUB_ITEMS.brandLogo;
  const isTitleSectionTitleContentActive = activeSubTabItem === TITLE_SUB_ITEMS.titleContent;
  const isTitleSectionAnsellLogoWhiteActive = activeSubTabItem === TITLE_SUB_ITEMS.ansellLogoWhite;
  const isTitleSectionAnsellLogoBlueActive = activeSubTabItem === TITLE_SUB_ITEMS.ansellLogoBlue;

  useEffect(() => {
    if(activeSectionIndex === -1){
      setIsWatermarked(null);
    }
    else{
      setIsWatermarked(sections[activeSectionIndex].IsWatermarked);
    }
  }, [sections, activeSectionIndex])

  useEffect(() => {
    if (!subcategories) {
      packsService.getSubcategoriesFromAssetbank(null, (res: any) => {
        if (res?.data?.value?.children) {
          dispatch(setSubcategories(res.data.value))
        }
      }, () => {
      });
    }
  }, [dispatch, subcategories])

  useEffect(() => {
    if (blob) {
      setFileUrl(URL.createObjectURL(blob))
      setBlob(null)
    }
  }, [blob])

  useEffect(() => {
    setSelectedValue(null)
  }, [activeSubTabItem, activeSubTab])

  useEffect(() => {
    if (selectedValue?.id) {
      setLoading(true)
      setBlob(null);
      setFileUrl(null);
      packsService.getAssetById(selectedValue.id, (res: any) => {
        setIsContentExists(!!parseInt(res.headers['content-length']))
        setIsPdf(checkIsPdfByTitle(res.headers['content-disposition']))
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        setBlob(blob)
        handleAssetBankFileChanged(selectedValue.name)
        setLoading(false)
      }, (err: any) => {
        setLoading(false)
      });
    }
  }, [selectedValue])

  const checkIsPdfByTitle = (title: string) => {
    if (!title) {
      return false;
    }

    return title.includes('.pdf')
  };

  const getFilePath = () => {
    let filePath = null;
    if (isTitleSectionAnsellLogoWhiteActive || isTitleSectionAnsellLogoBlueActive) {
      // out of logic. items placed not in "sections"
      if (isTitleSectionAnsellLogoWhiteActive) filePath = primaryLogoWhite
      if (isTitleSectionAnsellLogoBlueActive) filePath = primaryLogoBlue
    } else if (activeSubTab?.Content_source && activeSubTab?.Content_source[0]) {
      // items are in sections
      filePath = sections[activeSectionIndex].Content_source[0];
    }

    if (isTitleSectionBrandLogoActive) {
      filePath = sections[activeSectionIndex]?.Watermark?.BrandLogo
    }

    return filePath
  }

  const stringToAssetType = (str : string) : AssetType | null => {
    switch(str){
      case "Image":
        return AssetType.Image;
      case "Pdf":
        return AssetType.Pdf;
      case "Xlsx":
        return AssetType.Xlsx;
      default:
        return null;
    }
  }

  useEffect(() => {
    if (!subcategories) return;

    setBlob(null);
    setFileUrl(null);
    setLoading(false);
    setCurrentUploadPath(null);

    // title section
    if (isTitleSectionBrandProductActive) return;

    if (!blob) {
      setLoading(true)
      const filePath = getFilePath();

      if (!filePath) return;
      setAssetType(stringToAssetType(filePath.Type));
      const res2 = findNodePath([subcategories], +filePath.Location);
      if (res2) {
        setFolderName(res2[res2.length -1]);
        setCurrentUploadPath(res2.join('|'));
      }

      if (sections[activeSectionIndex]) {
        setFileIsMandatory(sections[activeSectionIndex].Mandatory);
      }

      packsService.getSearchAssetBank(filePath.Name_Contains, filePath.Location, (res: any) => {
        if (isTitleSectionBrandProductActive) return setLoading(false);

        setIsContentExists(!!parseInt(res.headers['content-length']))
        setIsPdf(checkIsPdfByTitle(res.headers['content-disposition']))
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        setBlob(blob);
        setLoading(false)
      }, (err: any) => {
        setLoading(false)
        console.log(err)
      });
    }
  }, [subcategories, activeSubTab, activeSubTabItem])

  // handle selection
  const handleChange = (value: any) => {
    setSelectedValue(value);
  }

  const getCategoryIdForAssetSearch = () => {
    if (!activeSubTab) return null;

    // there are specific place for ansell logo white & ansell logo blue items.
    if (isTitleSectionAnsellLogoWhiteActive) return primaryLogoWhite.Location;
    if (isTitleSectionAnsellLogoBlueActive) return primaryLogoBlue.Location;

    if (activeSubTab.Name.toLowerCase() === 'title') {
      if (isTitleSectionBrandLogoActive) {
        return activeSubTab.Watermark.BrandLogo.Location;
      }
    }

    if (activeSubTab.Content_source) {
      return activeSubTab.Content_source[0].Location
    }

    // wrong JSON
    setLoading(false)
    toast.error('Something went wrong. Please, contact development team')
    return null
  }

  const _loadSuggestions = (inputValue: string, callback: (options: any) => void) => {
    const category = getCategoryIdForAssetSearch();

    if (inputValue !== '' && inputValue.length >= 2 && category) {

      packsService.getSearchAssetBankFiles(inputValue, category, assetType, (res: any) => {
        callback(res.data.value);
      }, (err: any) => {
      });
    }
  };

  const isTitleSection = activeSubTab?.Name.toLowerCase() === 'title';

  const getFileSelector = () => {
    const fileSelector = (
      <div className="d-flex">
        <div className="folder-selector px-2">
          <ImLocation className="section-item-icon item-right-icon"/>
          <span className="pl-2">{folderName}</span>
        </div>
        <AsyncSelect
          className="w-100 pr-3"
          styles={{
            control: (provided: any) => ({
              ...provided,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }),
            container: (provided: any) => ({
              ...provided,
              paddingLeft: 0,
            }),
          }}
          cacheOptions
          value={selectedValue}
          getOptionLabel={(e: any) => e.name}
          getOptionValue={(e: any) => e.id}
          loadOptions={debounce(_loadSuggestions, 750)}
          onChange={handleChange}
        />
      </div>
    );

    if (isTitleSection) {
      if (isTitleSectionTitleContentActive || isTitleSectionBrandLogoActive || isTitleSectionAnsellLogoWhiteActive) {
        return fileSelector;
      }
    } else if (activeSubTab) {
      return fileSelector;
    }
  }

  const getContent = () => {
    if (isTitleSection) {
      if (isTitleSectionBrandProductActive) {
        return (
          <>
            <BlockField
              title="Brand"
              inputName={`sections[${activeSectionIndex}].Watermark.Brand`}
              inputType="text"
              isAssetStyle
            />
            <BlockField
              title="Product"
              inputName={`sections[${activeSectionIndex}].Watermark.Model`}
              inputType="text"
              isAssetStyle
            />
            <BlockField
              title="Optional Smaller Text"
              inputName={`sections[${activeSectionIndex}].Watermark.OptionalText`}
              inputType="text"
              isAssetStyle
            />
          </>
        )
      }
    }

    return null;
  }

  const handleAssetBankFileChanged = (filename: string) => {
    if (isTitleSectionAnsellLogoWhiteActive) {
      const updatedLogoWhite = { ...primaryLogoWhite }
      updatedLogoWhite.Name_Contains = filename;
      return dispatch(setPrimaryLogoWhite(updatedLogoWhite));
    }

    if (isTitleSectionAnsellLogoBlueActive) {
      const updatedLogoBlue = { ...primaryLogoBlue }
      updatedLogoBlue.Name_Contains = filename;
      return dispatch(setPrimaryLogoBlue(updatedLogoBlue));
    }

    // on set new blob - update redux state with new updated file
    const updatedSections = [...sections];

    if (isTitleSectionBrandLogoActive) {
      updatedSections[activeSectionIndex].Watermark.BrandLogo.Name_Contains = filename;

    } else {
      updatedSections[activeSectionIndex].Content_source[0].Name_Contains = filename;
    }

    dispatch(setTempSections(updatedSections));
  }

  const getFilePreviewContainer = () => {
    // display no-file if there is no url, or url were loading, and page changed to brand-product after
    if (!fileUrl || isTitleSectionBrandProductActive) {
      return null
    }

    if (!isContentExists) {
      const textMessage = fileIsMandatory
        ? 'The asset cannot be found. Upload a new asset or choose another one.'
        : 'This section is not mandatory';
      return (
        <div className="d-flex p-4 justify-content-center">
          {textMessage}
        </div>
      )
    }

    return <div className="preview-container">
      {
        isPdf
          ? <PdfViewer pdfUrl={fileUrl}/>
          : <img src={fileUrl} alt='img'/>
      }
    </div>
  }

  const handleChangeWatermark = () => {
    const newSections = [...sections];
    newSections[activeSectionIndex].IsWatermarked = !isWatermarked;
    dispatch(addHistorySnapshot({sections:newSections}));
  }

  function findNodePath(tree: ICategory[], targetId: number): string[] | null {
    for (const node of tree) {
      if (node.id === targetId) {
        return [node.name];
      }
  
      if (node.children) {
        const childPath = findNodePath(node.children, targetId);
        if (childPath) {
          return [node.name, ...childPath];
        }
      }
    }
    return null;
  }


  const handleUploaded = (data: UploadedAssetData) => { 
    setIsModalVisible(false);
    setSelectedValue(data);
  } 

  return (
    <div className="asset-block-comp">
      {getFileSelector()}
      {isWatermarked !== null && (
        <CustomCheckbox
          id="watermarkCheckbox"
          label="Watermark"
          value={isWatermarked}
          onChange={handleChangeWatermark}
        />
      )}
      
      {/*
      ----Upload feature uncomment if neccessary---- 
      { currentUploadPath &&
      (<>
        <div className="d-flex justify-content-end">
        <Button color="primary" onClick={() => setIsModalVisible(true)}>Upload</Button>
        </div>
        <AssetUploadModal isVisible={isModalVisible}
             handleCancel={()=>{setIsModalVisible(false)}}
             handleUploaded={handleUploaded}
             handleFail={()=>setIsModalVisible(false)}
             path = {currentUploadPath}
             assetType={assetType}/>
      </>)   
      } */}

  
      <div className="outlined-block">
        <Formik
          enableReinitialize={true}
          initialValues={{
            sections,
          }}
          validateOnChange={true}
          validate={(values) => {
            dispatch(setTempSections(values.sections));
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setSubmitting(false);
          }}
        >
          {() => (
            <Form className="p-2">
              {getContent()}
            </Form>
          )}
        </Formik>
        {activeSubTab &&
          <>
            {loading && <SpinnerLoad className="d-flex justify-content-center mt-3" size={50}/>}
            {getFilePreviewContainer()}
          </>
        }
        {
          !activeSubTab ? (
            <div className="info-text">
              <p>
                Choose item on the left menu to start editing section.
              </p>
            </div>
          ) : null
        }
      </div>
    </div>
  );
}

export default AssetBlock;
