import React from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { EXIT_CONFIRMATION_MESSAGE } from "hooks/useConfirmTabClose";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const UnsavedChangesModal = (
  {
    isOpen,
    handleCancel
  }: {
    isOpen: boolean
    handleCancel: any
  }) => {

  const {plannedPath} = useSelector((state: any) => state.app.newTemplatePage);
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} toggle={handleCancel}>
      <ModalHeader toggle={handleCancel}>Unsaved changes</ModalHeader>
      <ModalBody>
        <Label className="fw-bold text-primary font-14">{EXIT_CONFIRMATION_MESSAGE}</Label>
      </ModalBody>
      <ModalFooter>
        <Button className="text-white bg-secondary fw-bold" onClick={()=>{navigate(plannedPath)}}>
          Leave this Page
        </Button>
        <Button className="text-white bg-primary fw-bold" onClick={handleCancel}>
          Stay on this Page
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default UnsavedChangesModal;
