import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  clearData,
  setActiveSubTab,
  setActiveTab,
  setIndex,
  setLoading,
  setPackData,
  setIsDocumentEditor,
  setIsPageLoading,
  setHasUnsavedChanges,
  setShowUnsavedChangedModal,
  setPreviousSnap, 
  setNextSnap, 
  addHistorySnapshot, 
  setDocumentTeam,
} from 'redux/reducers/newTemplatePage/actionTypes';
import Scrollbar from 'components/shared/scrollbar/scrollbar';
import SectionBlock from "./sectionBlock/sectionBlock";
import NewTemplateHead from "./newTemplateHead/newTemplateHead";
import TableBlock from "./tableBlock/tableBlock";
import StylesBlock from "./stylesBlock/stylesBlock";
import packsService from "services/packsService";
import { AxiosError, AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useGetJson } from "./helpers/query";
import './newTemplatePage.scss';
import { toast } from "react-toastify";
import { DataTableType } from "components/pages/newTemplatePage/helpers/types";
import { TabTypes } from 'types';
import { useConfirmTabClose } from "hooks/useConfirmTabClose";
import UnsavedChangesModal from "components/pages/newTemplatePage/unsavedChangesModal";
import { useTableDataDiffers } from "components/pages/newTemplatePage/helpers/isTableDataDiffers";
import { useCtrlKeyEvent } from "hooks/keyboardEvents/useCtrlKeyEvent";
import { HistoryController } from "./historyController";
import CustomCheckbox from "components/shared/customCheckbox/customCheckbox";

export enum modeType {
  DocumentEditor = 'document-editor',
  TemplateEditor = 'template-editor',
}

interface ITemplate {
  id: string,
  templateJson: string,
  teamName: string,
  productGroupName: string,
  regionName: string,
  templateName: string,
  isArchived: boolean
}

const NewTemplatePage = (
  {
    mode = modeType.DocumentEditor,
    isPriceList = false,
  }: {
    mode: modeType
    isPriceList?: boolean;
  }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [templateDataSet, setTemplateDataSet] = useState<ITemplate | null>(null);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const isTableDataChanged = useTableDataDiffers();
  const isDocumentEditor = mode === modeType.DocumentEditor;
  const [isWatermarkedAll, setWatermarkedAll] = useState<boolean>(false);
  const {
    loading,
    activeTab,
    sections: temporarySections,
    referenceNumber: temporaryReferenceNumber,
    primaryLogoWhite,
    primaryLogoBlue,
    temporaryStyle,
    templateName,
    temporaryTableStyle,
    temporaryTableData,
    hasUnsavedChanges,
    isShowUnsavedChangedModal,
    temporaryDocumentName
  } = useSelector((state: any) => state.app.newTemplatePage);

  const historyBack = () => dispatch(setPreviousSnap());
  const historyForward = () => dispatch((setNextSnap()));

  useCtrlKeyEvent(() => historyBack(), 'z');
  useCtrlKeyEvent(() => historyForward(), 'y');

  const validationPackDataSet = useGetJson({ id: params.documentId });

  // detect page close and warn about
  useConfirmTabClose(hasUnsavedChanges);
  
  useEffect(()=>{
    setWatermarkedAll(temporarySections.every((s : any) =>s.IsWatermarked === true));
  }, [temporarySections])

  useEffect(() => {
    dispatch(setIsDocumentEditor(isDocumentEditor));
  }, [dispatch, isDocumentEditor])

  useEffect(() => {
    // is first load & has unsaved changed (quick solution)
    if (temporarySections.length) {
      if (isFirstLoad) {
        return setIsFirstLoad(false)
      }

      dispatch(setHasUnsavedChanges(true))
    }
  }, [dispatch, temporarySections, temporaryReferenceNumber, templateName, temporaryTableStyle, temporaryDocumentName])

  const successGetDetails = (resp: AxiosResponse) => {
    if (resp.data.success) {
      dispatch(setPackData(resp.data.value || []));
    } else {
      toast.error('Error fetching VP1')
    }

    dispatch(setLoading(false));
  }

  const failedGetDetails = () => dispatch(setLoading(false));

  const getTemplate = () => {
    if (params.documentId) {
      packsService.getTemplateDetails(params.documentId, (resp: AxiosResponse) => {
        if (resp.data.success) {
          dispatch(setPackData(resp.data.value || []));
          const templateName = resp.data.value.templateName;
          setTemplateDataSet(resp.data.value)
          const data = JSON.parse(resp.data.value.templateJson);
          
          if (data["Sections"] instanceof Array<Object>) {
            const sections: DataTableType[] = data["Sections"];
            dispatch(setDocumentTeam(data.Team))
            dispatch(addHistorySnapshot({
              sections: sections,
              templateName: templateName,
            }));
          }
        } else {
          toast.error('Error fetching Template')
        }

        dispatch(setLoading(false));
      }, failedGetDetails)
    }
  }

  useEffect(() => {
    if (params.documentId) {
      dispatch(setLoading(true));
      if (isDocumentEditor) {
        // Validation pack data
        packsService.getPackDetails(params.documentId, successGetDetails, failedGetDetails);
      } else {
        getTemplate();
      }
    }

    return () => {
      dispatch(clearData());
    }
  }, [dispatch, params.documentId]);

  const handleSaveValidationPack = () => {
    if (!isPriceList) {
      if (!temporaryReferenceNumber?.length) {
        return toast.info('Ref Number cannot be empty')
      }
    }

    dispatch(setLoading(true));
    const json = validationPackDataSet.jsonData;

    if (!isPriceList) {
      json.ReferenceNumber = temporaryReferenceNumber; 
    }
    json.PrimaryLogoBlue = primaryLogoBlue;
    json.PrimaryLogoWhite = primaryLogoWhite;

    if (temporaryStyle?.style) {
      json.Style = temporaryStyle.style;
    }

    if (temporaryTableStyle?.style) {
      json.TableStyle = temporaryTableStyle.style;
    }

    if (temporarySections) {
      json.Sections = temporarySections;
    }
    
    if (temporaryDocumentName){
      json.Name = temporaryDocumentName;
    }


    if (temporaryTableData) {
      // if header were changed - reorder rows as well
      json.Tables = temporaryTableData.map((table: any) => {
        const rows = table.rows.map((row: any) => (
          table.headers.map((header: any) =>
            row.find((row: any) => row.index === header.index)
          )
        ))

        return { ...table, rows }
      });
    }

    if (params.documentId) {
      packsService.updatePack(params.documentId, JSON.stringify(json),
        (resp: AxiosResponse) => {
          validationPackDataSet.reloadData();
          dispatch(setIndex(-1))
          dispatch(setActiveTab(TabTypes.Sections))
          dispatch(setActiveSubTab(null))
          dispatch(setLoading(false));

          regenerateDocument(params.documentId)
        }, (err: AxiosError) => {
          dispatch(setLoading(false))
        })
    } else {
      dispatch(setLoading(false));
      toast.error('Something went wrong')
    }
  }

  const handleSaveTemplate = () => {
    if (!templateDataSet?.templateJson) {
      console.log("something went wrong, check here")
      return;
    }

    const updatedJson = JSON.parse(templateDataSet?.templateJson);
    updatedJson.Sections = temporarySections

    const updatedTemplateData = Object.assign({}, templateDataSet);
    updatedTemplateData.templateJson = JSON.stringify(updatedJson)
    updatedTemplateData.templateName = templateName

    const requestData = {
      templateId: updatedTemplateData.id,
      templateJson: updatedTemplateData.templateJson,
      teamName: updatedTemplateData.teamName,
      productGroupName: updatedTemplateData.productGroupName,
      regionName: updatedTemplateData.regionName,
      templateName: updatedTemplateData.templateName,
      isArchived: updatedTemplateData.isArchived
    }

    packsService.updateTemplate(requestData,
      (resp: AxiosResponse) => {
        if (resp.data.success) {
          toast.success('Template updated successfully')
          navigate(-1);
        }

      }, (err: AxiosError) => {
        dispatch(setLoading(false))
      })
  }

  const handleSave = () => {
    dispatch(setHasUnsavedChanges(false))
    if (isDocumentEditor) {
      handleSaveValidationPack()
    } else {
      handleSaveTemplate();
    }
  }

  const regenerateDocument = (id?: string) => {
    const errorMessage = 'Something went wrong. The document is not regenerated'
    if (!id) return toast.error(errorMessage);

    dispatch(setIsPageLoading(true));
    dispatch(setHasUnsavedChanges(false))

    packsService.regenerateDocument(id, (res: any) => {
      packsService.handleGenerationErrors(res);

      if (!res.data.success) {
        toast.error('Regenerating Error. Please, contact development team');
        return dispatch(setIsPageLoading(false));
      }

      dispatch(setIsPageLoading(false));
      toast.success('Regenerated successfully');
      handleCancelEditing(true);
    }, () => {
      dispatch(setIsPageLoading(false));
      toast.error(errorMessage);
    });
  }

  const handleCancelEditing = (forceRedirect = false) => {
    if(forceRedirect){
      return cancelEditing();
    }
    if (hasUnsavedChanges || isTableDataChanged.isChanged) {
      return dispatch(setShowUnsavedChangedModal(true))
    }

    cancelEditing();
  }

  const cancelEditing = () => {
    if (mode === modeType.TemplateEditor) {
      navigate(-1);
    } else {
      navigate('/document-details/' + params.documentId)
    }
  }

  const getCurrentTabContent = () => {
    switch (activeTab) {
      case TabTypes.Sections:
        return <SectionBlock 
          isPriceList={isPriceList} />
      case TabTypes.Tables:
        return <TableBlock
          tableStyle={validationPackDataSet.tableStyle}
        />
      case TabTypes.Styles:
        return <StylesBlock
          styleData={validationPackDataSet.style}
        />
      default:
        return null;
    }
  }

  const handleChangeIsWatermarkAll = () => {
      const newSections = [...temporarySections];
      newSections.forEach((item: any) => item.IsWatermarked = !isWatermarkedAll);
      dispatch(addHistorySnapshot({sections: newSections}));
  }

  return (
    <Scrollbar>
      <UnsavedChangesModal
        isOpen={isShowUnsavedChangedModal}
        handleCancel={() => dispatch(setShowUnsavedChangedModal(false))}
      />

      <HistoryController back={historyBack} forward={historyForward}/>

      <div className="new-template-page h-100 p-4">
        <NewTemplateHead
          handleSave={handleSave}
          handleGoBack={handleCancelEditing}
          loading={loading}
          isPriceList={isPriceList}
        />
        {isDocumentEditor && activeTab === TabTypes.Sections &&
        <CustomCheckbox 
          id="addAllCheckbox" 
          label="Add watermark to all sections in the document" 
          value={isWatermarkedAll}
          onChange={handleChangeIsWatermarkAll}/>
        }
        
        <div className="new-template-page-container">
          {validationPackDataSet ? getCurrentTabContent() : null}
        </div>
      </div>
    </Scrollbar>
  );
}

export default NewTemplatePage;
