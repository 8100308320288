import { AuthActionTypes, CLEAR_AUTH_DATA, SET_AUTH_DATA, SET_USER_ROLES } from './actionTypes';
import { AuthData } from 'types';

const initState: AuthData = {
  userName: '',
  name: '',
  bearerToken: '',
  roles: null
};


export default (state: AuthData = initState, action: AuthActionTypes) => {

  switch (action.type) {
    case SET_AUTH_DATA:
      return { ...action.payload };
    case SET_USER_ROLES:
      return {...state, roles: action.payload}
    case CLEAR_AUTH_DATA:
      return { ...initState };
    default:
      return state;
  }
}
