import { FunctionComponent } from "react";
import { Form, Formik } from "formik";
import {addHistorySnapshot} from "redux/reducers/newTemplatePage/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import StylingBlock from "components/pages/newTemplatePage/tableBlock/stylingBlock";
import BlockField from "components/pages/newTemplatePage/tableBlock/blockField";
import ContentSkeleton from "../contentSkeleton";

type StylesDataProps = {
  styleData: any
}

const StylesBlock: FunctionComponent<StylesDataProps> = ({styleData}) => {
  const dispatch = useDispatch();
  const setStyle = (styles: any) => { 
    dispatch(addHistorySnapshot({styles: styles}));
  };
  const styles = useSelector((state: any) => state.app.newTemplatePage.temporaryStyle);

  return (
    <ContentSkeleton
      sidebar={
        <div className="w-100 d-block">
          {
            styles?.style ? (
              <Formik
                initialValues={{
                  styles,
                }}
                onSubmit={(values) => {
                  setStyle(values.styles);
                }}
                validateOnChange={true}
                validateOnBlur={false}
                enableReinitialize={true}
                validate={(values) => {
                  setStyle(values.styles);
                  return {};
                }}
              >
                {({ values }) => (
                  <Form className="p-3 w-75">
                    <StylingBlock title="">
                      {
                        styleData?.style
                          ? (
                            <>
                              <BlockField
                                title="Colour"
                                inputName="styles.style.Color"
                                inputType="color"
                              />
                              {/*<BlockField*/}
                              {/*  title="Font family"*/}
                              {/*  inputName="styles.style.FontName"*/}
                              {/*  inputType="select"*/}
                              {/*  defaultValue={styleData.style.FontName}*/}
                              {/*  selectOptions={defaultFonts}*/}
                              {/*/>*/}
                            </>
                          ) : null
                      }
                    </StylingBlock>
                  </Form>
                )}
              </Formik>
            ) : null
          }
        </div>
      }
    />
  )
}

export default StylesBlock;
