import Client from 'services/axiosService';
import { AxiosError, AxiosResponse } from 'axios';
import { CreatePriceListDocumentData, PriceListLoadData, templatesListLoadData } from "types";

const ApiUrl = process.env.REACT_APP_API_URL;

const priceListProvider = {
  getPriceLists(data: PriceListLoadData, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/GetAll`,
      method: 'post',
      data: data
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  getPriceListTemplates(data: templatesListLoadData | null, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Templates/get-all-paged`,
      method: 'post',
      data: {
        ...data,
        pdfType: 1,
      }
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  createPriceListDocument(data: CreatePriceListDocumentData, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/Documents/price-list`,
      method: 'put',
      data
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  },
  generatePDF(documentId: string, success: Function, error?: Function) {
    Client().request({
      url: `${ApiUrl}/PdfGeneration/GeneratePdf`,
      method: 'post',
      responseType: 'blob',
      data: {
        documentId,
        "team": 0,
        "docTitle": ""
      }
    })
      .then((resp: AxiosResponse) => {
        success(resp);
      }).catch((err: AxiosError) => {
      if (error) {
        error(err);
      }
    });
  }
}

export default priceListProvider;
