import React, { FunctionComponent, ReactNode } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {addHistorySnapshot} from 'redux/reducers/newTemplatePage/actionTypes';
import { TabTypes } from 'types';

type AddPackPageProps = {
  sidebar?: ReactNode,
  content?: ReactNode,
  contentTitle?: ReactNode,
}

const ContentSkeleton: FunctionComponent<AddPackPageProps> = (
  {
    sidebar,
    content,
    contentTitle,
  }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state: any) => state.app.newTemplatePage.activeTab);

  const setTab = (tab: TabTypes) => dispatch(addHistorySnapshot({activeTab: tab}));
  const {
    temporaryTableData,
    temporaryStyle,
  } = useSelector((state: any) => state.app.newTemplatePage);

  return (
    <>
      <div className="section-top">
        <div className="header-content">
          <div
            className={`section-title-item ${activeTab === TabTypes.Sections && 'active'}`}
            onClick={() => setTab(TabTypes.Sections)}
          >
            Section
          </div>
          {
            temporaryTableData?.length ? (
              <div
                className={`section-title-item ${activeTab === TabTypes.Tables && 'active'}`}
                onClick={() => setTab(TabTypes.Tables)}
              >
                Tables
              </div>
            ) : null
          }
          {
            temporaryStyle ? (
              <div
                className={`section-title-item ${activeTab === TabTypes.Styles && 'active'}`}
                onClick={() => setTab(TabTypes.Styles)}
              >
                Style Colour
              </div>
            ) : null
          }

        </div>
        {sidebar}
      </div>
      <div className="w-100 background-grey">
        <div className="info-block">
          {
            contentTitle
              ? contentTitle
              : (
                <div className="table-block__list-title">
                  <div className="list-title">
                  </div>
                </div>
              )
          }
        </div>
        <div className="info-block-content">
          <div className='p-2'>
            {content}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentSkeleton;
