import { authConfig } from 'types';

export const msalConfig: authConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_APPLICATION_ID || '' ,
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY || '',
    redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URL || ''
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: [ process.env.REACT_APP_AZURE_AD_SCOPE || '' ]
};

export const graphConfig = {
  graphMeEndpoint: (process.env.REACT_APP_AZURE_GRAPH_PHOTO_API || '') + '/$value'
};
